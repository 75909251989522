import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService, InfoService } from '../../global';

@Component({
  selector: 'app-redirect-pembatalan-transaksi',
  templateUrl: './redirect-pembatalan-transaksi.component.html',                                                                                                                     
  styleUrls: ['./redirect-pembatalan-transaksi.component.css']
})
export class RedirectPembatalanTransaksiComponent implements OnInit {

  link  = [
    'daftar-kirim-pembatalan-pengajuan-transaksi-v2',
    'daftar-pembatalan-pengajuan-transaksi-v2',
    'daftar-terima-pembatalan-pengajuan-transaksi-v2'
  ];

  constructor(
    private route: Router,
    private info: InfoService,
    private settingsService: SettingsService
  ) { }

  ngOnInit() {
    let urlList = this.settingsService.getCurrentURL();
        for (let i=0; i<this.link.length; i++){
            if (!(urlList['/' + this.link[i]] == undefined || urlList['/' + this.link[i]] == null || urlList['/' + this.link[i]] != 1)){
                localStorage.setItem('pembatalan-transaksi-menu', String(i));
                this.route.navigate([this.link[i]]);
                break;
            }
        }
  }

}
