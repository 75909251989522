import { Pipe, PipeTransform } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

@Pipe({name: 'debughtml'})
export class DebugHTMLPipe implements PipeTransform {

  transform(myVar: any) {
      console.log(myVar)
      console.log(JSON.parse(myVar))
  }

}