export const routePhk = [
    { path: 'daftar-kirim-pengajuan-phk', loadChildren: () => import('./page-phk.module').then(m => m.DaftarKirimPengajuanPhkModule)},
    { path: 'daftar-pengajuan-phk', loadChildren: () => import('./page-phk.module').then(m => m.DaftarPengajuanPhkModule)},
    { path: 'daftar-terima-pengajuan-phk', loadChildren: () => import('./page-phk.module').then(m => m.DaftarTerimaPengajuanPhkModule)},

    { path: 'daftar-kirim-pengajuan-phk-v2', loadChildren: () => import('./page-phk.module').then(m => m.DaftarKirimPengajuanPhkV2Module)},
    { path: 'daftar-pengajuan-phk-v2', loadChildren: () => import('./page-phk.module').then(m => m.DaftarPengajuanPhkV2Module)},
    { path: 'daftar-terima-pengajuan-phk-v2', loadChildren: () => import('./page-phk.module').then(m => m.DaftarTerimaPengajuanPhkV2Module)},

    { path: 'pengajuan-phk', loadChildren: () => import('./page-phk.module').then(m => m.PengajuanPhkModule)},
    { path: 'daftar-kompensasi-phk', loadChildren: () => import('./page-phk.module').then(m => m.DaftarKompensasiPhkModule)},
    { path: 'daftar-kurang-lebih-bayar-pph21', loadChildren: () => import('./page-phk.module').then(m => m.DaftarKurangLebihBayarPph21Module)},
    { path: 'hitung-pesangon', loadChildren: () => import('./page-phk.module').then(m => m.HitungPesangonModule)},
    { path: 'hitung-pesangon-v2', loadChildren: () => import('./page-phk.module').then(m => m.HitungPesangonV2Module)},
];
