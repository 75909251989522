export const routePersonalAffairs = [
    // { path: 'pengajuan-resign', loadChildren : './page-personal-affairs.module#PengajuanResignModule' },
    { path: 'daftar-pengajuan-resign', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarPengajuanResignModule) },
    { path: 'daftar-terima-pengajuan-resign', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarTerimaPengajuanResignModule) },
    { path: 'daftar-kirim-pengajuan-resign-v2', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarKirimPengajuanResignModule) },
    { path: 'daftar-kirim-pengajuan-resign', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarKirimPengajuanResignSingleModule) },
    { path: 'daftar-kirim-pengajuan-hold-pinjaman', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarKirimPengajuanHoldPinjamanModule)},
    { path: 'daftar-kirim-pengajuan-hold-pinjaman-v2', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarKirimPengajuanHoldPinjamanV2Module)},
    { path: 'pengajuan-hold-atau-pelunasan-pinjaman', loadChildren : () => import('./page-personal-affairs.module').then(m => m.PengajuanHoldAtauPelunasanPinjamanModule)},
    { path: 'daftar-pengajuan-hold-pinjaman', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarPengajuanHoldPinjamanModule)},
    { path: 'daftar-pengajuan-hold-pinjaman-v2', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarPengajuanHoldPinjamanV2Module)},
    { path: 'daftar-terima-pengajuan-hold-pinjaman', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarTerimaPengajuanHoldPinjamanModule)},
    { path: 'daftar-terima-pengajuan-hold-pinjaman-v2', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarTerimaPengajuanHoldPinjamanV2Module)},
    { path: 'pengajuan-reimburs', loadChildren : () => import('./page-personal-affairs.module').then(m => m.PengajuanReimbursModule) },
    { path: 'daftar-pengajuan-reimburs', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarPengajuanReimbursModule) }, 
    { path: 'daftar-kirim-pengajuan-reimburs', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarKirimPengajuanReimbursModule) },    
    { path: 'daftar-terima-pengajuan-reimburs', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarTerimaPengajuanReimbursV2Module) },
    { path: 'daftar-pengajuan-reimburs-v2', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarPengajuanReimbursV2Module) }, 
    { path: 'daftar-kirim-pengajuan-reimburs-v2', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarKirimPengajuanReimbursV2Module) },    
    { path: 'daftar-terima-pengajuan-reimburs-v2', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarTerimaPengajuanReimbursV2Module) },
    { path: 'daftar-pengajuan-pinjaman', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarTerimaPengajuanPinjamanModule) },
    { path: 'daftar-pengajuan-pinjaman-v2', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarTerimaPengajuanPinjamanV2Module) },
    { path: 'pengajuan-pinjaman', loadChildren : () => import('./page-personal-affairs.module').then(m => m.PengajuanPinjamanModule) },
    { path: 'daftar-terima-pengajuan-pinjaman', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarPengajuanPinjamanModule) },
    { path: 'daftar-kirim-pengajuan-pinjaman', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarKirimPengajuanPinjamanModule) },
    { path: 'daftar-terima-pengajuan-pinjaman-v2', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarPengajuanPinjamanV2Module) },
    { path: 'daftar-kirim-pengajuan-pinjaman-v2', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarKirimPengajuanPinjamanV2Module) },
    { path: 'pengajuan-reimburs-perjalanan-dinas', loadChildren : () => import('./page-personal-affairs.module').then(m => m.PengajuanReimbursRealisasiModule) },
	{ path: 'daftar-pengajuan-reimburs-perjalanan-dinas', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarPengajuanReimbursRealisasiModule) },
	{ path: 'daftar-kirim-pengajuan-reimburs-perjalanan-dinas', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarKirimPengajuanReimbursRealisasiModule) },
	{ path: 'daftar-terima-pengajuan-reimburs-perjalanan-dinas', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarTerimaPengajuanReimbursRealisasiModule) },
    { path: 'daftar-pengajuan-reimburs-perjalanan-dinas-v2', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarPengajuanReimbursRealisasiV2Module) },
	{ path: 'daftar-kirim-pengajuan-reimburs-perjalanan-dinas-v2', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarKirimPengajuanReimbursRealisasiV2Module) },
	{ path: 'daftar-terima-pengajuan-reimburs-perjalanan-dinas-v2', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarTerimaPengajuanReimbursRealisasiV2Module) },
    { path: 'pengajuan-pensiun', loadChildren : () => import('./page-personal-affairs.module').then(m => m.PengajuanPensiunModule) },
	{ path: 'daftar-kirim-pengajuan-pensiun', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarKirimPengajuanPensiunModule) },
    { path: 'daftar-terima-pengajuan-pensiun', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarTerimaPengajuanPensiunModule) },
    { path: 'daftar-kirim-pengajuan-pensiun-v2', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarKirimPengajuanPensiunV2Module) },
	{ path: 'daftar-terima-pengajuan-pensiun-v2', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarTerimaPengajuanPensiunV2Module) },
	{ path: 'daftar-kompensasi-pensiun', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarKompensasiPensiunModule) },
    { path: 'hitung-pesangon-pensiun', loadChildren : () => import('./page-personal-affairs.module').then(m => m.HitungPesangonPensiunModule) },
    { path: 'hitung-pesangon-pensiun-v2', loadChildren : () => import('./page-personal-affairs.module').then(m => m.HitungPesangonPensiunV2Module) },
    { path: 'daftar-pengajuan-pensiun', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarPengajuanPensiunModule) },
    { path: 'daftar-pengajuan-pensiun-v2', loadChildren : () => import('./page-personal-affairs.module').then(m => m.DaftarPengajuanPensiunV2Module) },
    { path: 'histori-pinjaman-pribadi', loadChildren : () => import('./page-personal-affairs.module').then(m => m.HistoriPinjamanPribadiModule) },
    { path: 'histori-pinjaman-admin', loadChildren : () => import('./page-personal-affairs.module').then(m => m.HistoriPinjamanAdminModule) },
    { path: 'pengajuan-resign', loadChildren : () => import('./page-personal-affairs.module').then(m => m.PengajuanResignAdminModule) },
    { path: 'upload-download-formulir-resign-v2', loadChildren : () => import('./page-personal-affairs.module').then(m => m.UploadDownloadFormulirResignModule) },
    { path: 'upload-download-formulir-resign', loadChildren : () => import('./page-personal-affairs.module').then(m => m.UploadDownloadFormulirResignSingleModule) },
    { path: 'import-data-pinjaman', loadChildren: () => import('./page-personal-affairs.module').then(m => m.ImportDataPinjamanModule) },

];

