import { NgModule }     from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule, MessagesModule, GrowlModule, ProgressBarModule, ConfirmDialogModule, DialogModule, PaginatorModule } from 'primeng/primeng';

//import { globalComps } from './';
import * as global from './';

const globalComps = [
	global.AlertComp,
	global.InfoComp,
    global.LoaderComp,
	global.ImagePipe,
	global.ImageViewPipe,
	global.DebugHTMLPipe,
//    global.PDFPipe,
	global.RoundUpPipe,
	global.RoundNumPipe,
	global.RoundDownPipe,
    global.TrueFalsePipe,
    global.RoundNumPipe,
	global.ReportComp,
	global.BlockableDiv,
	global.AppCalenderComponent,
	global.AppDataTableComponent,
	global.AppBlockUIComponent,
	global.OnlyNumber,
	global.NoExponen
];


@NgModule({ 
	declarations :[ ...globalComps, 			
			global.DTRadioButton, 
			global.DTCheckbox, 
			global.ColumnHeaders, 
			global.ColumnFooters, 
			global.TableBody, 
			global.ScrollableView  ],
	imports : [ CommonModule, SharedModule, MessagesModule, GrowlModule, 
				ProgressBarModule, ConfirmDialogModule, DialogModule, PaginatorModule],
	exports : [ ...globalComps]
})

export class GlobalModule {}

