import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { StepsModule, ButtonModule } from 'primeng/primeng';

import { ProsesRegistrasiPegawaiComponent } from './proses-registrasi-pegawai/proses-registrasi-pegawai.component';
import { ProsesPenggajianComponent } from './proses-penggajian/proses-penggajian.component';
import { ProsesPenggajian2Component } from './proses-penggajian2/proses-penggajian2.component';
import { ResignMenuComponent } from './resign-menu/resign-menu.component';
import { MenuUploadComponent } from './menu-upload/menu-upload.component';
import { MenuEvaluasiJabatanComponent } from './menu-evaluasi-jabatan/menu-evaluasi-jabatan.component';
import { MenuRewardComponent } from './menu-reward/menu-reward.component';
import { MenuValidasiSipStrComponent } from './menu-validasi-sip-str/menu-validasi-sip-str.component';
import { MenuSanksiComponent } from './menu-sanksi/menu-sanksi.component';
import { MenuCutiComponent } from './menu-cuti/menu-cuti.component';
import { MenuLemburComponent } from './menu-lembur/menu-lembur.component';
import { MenuPembatalanTransaksiComponent } from './menu-pembatalan-transaksi/menu-pembatalan-transaksi.component';
import { MenuPhkComponent } from './menu-phk/menu-phk.component';
import { MenuPensiunComponent } from './menu-pensiun/menu-pensiun.component';
import { MenuReimbursPerjalananDinasComponent } from './menu-reimburs-perjalanan-dinas/menu-reimburs-perjalanan-dinas.component';
import { MenuPerjalananDinasComponent } from './menu-perjalanan-dinas/menu-perjalanan-dinas.component';
import { MenuPerubahanJadwalKerjaComponent } from './menu-perubahan-jadwal-kerja/menu-perubahan-jadwal-kerja.component';
import { MenuPerubahanPinjamanComponent } from './menu-perubahan-pinjaman/menu-perubahan-pinjaman.component';
import { MenuPinjamanComponent } from './menu-pinjaman/menu-pinjaman.component';
import { MenuReimbursComponent } from './menu-reimburs/menu-reimburs.component';
import { MenuOrientasiPegawaiComponent } from './menu-orientasi-pegawai/menu-orientasi-pegawai.component';
import { MenuKesejahteraanComponent } from './menu-kesejahteraan/menu-kesejahteraan.component';
import { MenuKredensialComponent } from './menu-kredensial/menu-kredensial.component';
import { MenuPenilaianKerjaComponent } from './menu-penilaian-kerja/menu-penilaian-kerja.component';
import { MenuPkbSponsorshipComponent } from './menu-pkb-sponsorship/menu-pkb-sponsorship.component';

@NgModule({
	declarations: [MenuKesejahteraanComponent],
	imports: [CommonModule, StepsModule, ButtonModule, TranslateModule],
	exports: [MenuKesejahteraanComponent]
})
export class MenuKesejahteraanModule { }

@NgModule({
	declarations: [MenuOrientasiPegawaiComponent],
	imports: [CommonModule, StepsModule, ButtonModule, TranslateModule],
	exports: [MenuOrientasiPegawaiComponent]
})
export class MenuOrientasiPegawaiModule { }

@NgModule({
	declarations: [MenuReimbursComponent],
	imports: [CommonModule, StepsModule, ButtonModule, TranslateModule],
	exports: [MenuReimbursComponent]
})
export class MenuReimbursModule { }

@NgModule({
	declarations: [MenuValidasiSipStrComponent],
	imports: [CommonModule, StepsModule, ButtonModule, TranslateModule],
	exports: [MenuValidasiSipStrComponent]
})
export class MenuValidasiSipStrModule { }

@NgModule({
	declarations: [MenuPinjamanComponent],
	imports: [CommonModule, StepsModule, ButtonModule, TranslateModule],
	exports: [MenuPinjamanComponent]
})
export class MenuPinjamanModule { }

@NgModule({
	declarations: [MenuPerubahanPinjamanComponent],
	imports: [CommonModule, StepsModule, ButtonModule, TranslateModule],
	exports: [MenuPerubahanPinjamanComponent]
})
export class MenuPerubahanPinjamanModule { }

@NgModule({
	declarations: [MenuPerubahanJadwalKerjaComponent],
	imports: [CommonModule, StepsModule, ButtonModule, TranslateModule],
	exports: [MenuPerubahanJadwalKerjaComponent]
})
export class MenuPerubahanJadwalKerjaModule { }

@NgModule({
	declarations: [MenuPerjalananDinasComponent],
	imports: [CommonModule, StepsModule, ButtonModule, TranslateModule],
	exports: [MenuPerjalananDinasComponent]
})
export class MenuPerjalananDinasModule { }


@NgModule({
	declarations: [MenuReimbursPerjalananDinasComponent],
	imports: [CommonModule, StepsModule, ButtonModule, TranslateModule],
	exports: [MenuReimbursPerjalananDinasComponent]
})
export class MenuReimbursPerjalananDinasModule { }

@NgModule({
	declarations: [MenuPensiunComponent],
	imports: [CommonModule, StepsModule, ButtonModule, TranslateModule],
	exports: [MenuPensiunComponent]
})
export class MenuPensiunModule { }

@NgModule({
	declarations: [MenuPhkComponent],
	imports: [CommonModule, StepsModule, ButtonModule, TranslateModule],
	exports: [MenuPhkComponent]
})
export class MenuPhkModule { }

@NgModule({
	declarations: [MenuPembatalanTransaksiComponent],
	imports: [CommonModule, StepsModule, ButtonModule, TranslateModule],
	exports: [MenuPembatalanTransaksiComponent]
})
export class MenuPembatalanTransaksiModule { }

@NgModule({
	declarations: [MenuLemburComponent],
	imports: [CommonModule, StepsModule, ButtonModule, TranslateModule],
	exports: [MenuLemburComponent]
})
export class MenuLemburModule { }

@NgModule({
	declarations: [MenuCutiComponent],
	imports: [CommonModule, StepsModule, ButtonModule, TranslateModule],
	exports: [MenuCutiComponent]
})
export class MenuCutiModule { }

@NgModule({
	declarations: [MenuSanksiComponent],
	imports: [CommonModule, StepsModule, ButtonModule, TranslateModule],
	exports: [MenuSanksiComponent]
})
export class MenuSanksiModule { }

@NgModule({
	declarations: [MenuRewardComponent],
	imports: [CommonModule, StepsModule, ButtonModule, TranslateModule],
	exports: [MenuRewardComponent]
})
export class MenuRewardModule { }


@NgModule({
	declarations: [MenuEvaluasiJabatanComponent],
	imports: [CommonModule, StepsModule, ButtonModule, TranslateModule],
	exports: [MenuEvaluasiJabatanComponent]
})
export class MenuEvaluasiJabatanModule { }

@NgModule({
	declarations: [MenuUploadComponent],
	imports: [CommonModule, StepsModule, ButtonModule, TranslateModule],
	exports: [MenuUploadComponent]
})
export class MenuUploadModule { }

@NgModule({
	declarations: [ResignMenuComponent],
	imports: [CommonModule, StepsModule, ButtonModule, TranslateModule],
	exports: [ResignMenuComponent]
})
export class ResignMenuModule { }

@NgModule({
	declarations: [ProsesRegistrasiPegawaiComponent],
	imports: [CommonModule, StepsModule, ButtonModule, TranslateModule],
	exports: [ProsesRegistrasiPegawaiComponent]
})
export class ProsesRegistrasiPegawaiModule { }


@NgModule({
	declarations: [ProsesPenggajianComponent],
	imports: [CommonModule, StepsModule, ButtonModule, TranslateModule],
	exports: [ProsesPenggajianComponent]
})
export class ProsesPenggajianModule { }

@NgModule({
	declarations: [ProsesPenggajian2Component],
	imports: [CommonModule, StepsModule, ButtonModule, TranslateModule],
	exports: [ProsesPenggajian2Component]
})
export class ProsesPenggajian2Module { }


@NgModule({
	declarations: [MenuKredensialComponent],
	imports: [CommonModule, StepsModule, ButtonModule, TranslateModule],
	exports: [MenuKredensialComponent]
})
export class MenuKredensialModule {}

@NgModule({
	declarations: [MenuPenilaianKerjaComponent],
	imports: [CommonModule, StepsModule, ButtonModule, TranslateModule],
	exports: [MenuPenilaianKerjaComponent]
})
export class MenuPenilaianKerjaModule {}

@NgModule({
	declarations: [MenuPkbSponsorshipComponent],
	imports: [CommonModule, StepsModule, ButtonModule, TranslateModule],
	exports: [MenuPkbSponsorshipComponent]
})
export class MenuPkbSponsorshipModule {}