//Menu Surat Keputusan
export const routeMasterSK = [
    { path: 'surat-keputusan', loadChildren : () => import('./master-surat-keputusan.module').then(m => m.SuratKeputusanModule) },
    { path: 'surat-keputusan-sk', loadChildren : () => import('./master-surat-keputusan.module').then(m => m.SuratKeputusanModule) },
    { path: 'surat-keputusan-approval-orientasi', loadChildren : () => import('./master-surat-keputusan.module').then(m => m.SuratKeputusanApprovalModule) },
    { path: 'surat-keputusan-approval-cuti', loadChildren : () => import('./master-surat-keputusan.module').then(m => m.SuratKeputusanApprovalModule) },
    { path: 'surat-keputusan-approval-lembur', loadChildren : () => import('./master-surat-keputusan.module').then(m => m.SuratKeputusanApprovalModule) },
    { path: 'surat-keputusan-approval-resign', loadChildren : () => import('./master-surat-keputusan.module').then(m => m.SuratKeputusanApprovalModule) },
    { path: 'surat-keputusan-approval-reimburs', loadChildren : () => import('./master-surat-keputusan.module').then(m => m.SuratKeputusanApprovalModule) },         
    { path: 'surat-keputusan-approval-award-offence', loadChildren : () => import('./master-surat-keputusan.module').then(m => m.SuratKeputusanApprovalModule) },
    { path: 'surat-keputusan-approval-reimburs-perjalanan-dinas', loadChildren : () => import('./master-surat-keputusan.module').then(m => m.SuratKeputusanApprovalModule) },
    { path: 'surat-keputusan-rate-loan-pinjaman', loadChildren : () => import('./master-surat-keputusan.module').then(m => m.SuratKeputusanRateLoanModule) },
    { path: 'surat-keputusan-approval-pinjaman', loadChildren : () => import('./master-surat-keputusan.module').then(m => m.SuratKeputusanApprovalModule) },
    { path: 'surat-keputusan-approval-perjalanan-dinas', loadChildren : () => import('./master-surat-keputusan.module').then(m => m.SuratKeputusanApprovalModule) },
    { path: 'surat-keputusan-approval-phk', loadChildren : () => import('./master-surat-keputusan.module').then(m => m.SuratKeputusanApprovalModule) },
    { path: 'surat-keputusan-approval-pensiun', loadChildren : () => import('./master-surat-keputusan.module').then(m => m.SuratKeputusanApprovalModule) },
    { path: 'surat-keputusan-approval-evaluasi', loadChildren : () => import('./master-surat-keputusan.module').then(m => m.SuratKeputusanApprovalModule) },
    { path: 'pegawai-sk-gaji', loadChildren : () => import('./master-pegawai-sk.module').then(m => m.PegawaiSkGajiModule) },
    { path: 'pegawai-sk-gaji-ba', loadChildren : () => import('./master-pegawai-sk.module').then(m => m.PegawaiSkGajiBAModule) },
    { path: 'pegawai-sk-gaji-bak', loadChildren : () => import('./master-pegawai-sk.module').then(m => m.PegawaiSkGajiBAKModule) },
    { path: 'pegawai-sk-medical', loadChildren : () => import('./master-pegawai-sk.module').then(m => m.PegawaiSkMedicalModule) },
    { path: 'pegawai-sk-status', loadChildren : () => import('./master-pegawai-sk.module').then(m => m.PegawaiSkStatusModule) },
    { path: 'pegawai-sk-waktu-kerja', loadChildren : () => import('./master-pegawai-sk.module').then(m => m.PegawaiSkWaktuKerjaModule) },
    { path: 'master-struktur-nomor-sk', loadChildren : () => import('./master-khusus.module').then(m => m.StrukturNomorModule) },
    { path: 'master-jenis-keputusan-sk', loadChildren : () => import('./master-khusus.module').then(m => m.JenisKeputusanModule) },
    { path: 'master-jenis-dokumen-sk', loadChildren : () => import('./master-dokumen.module').then(m => m.JenisDokumenModule) },
    { path: 'master-kategori-dokumen-sk', loadChildren : () => import('./master-dokumen.module').then(m => m.KategoriDokumenModule) },
    { path: 'pegawai-sk-fasilitas-fasilitas-pegawai', loadChildren : () => import('./master-pegawai-sk.module').then(m => m.MasterPegawaiSkFasilitasModule) },
    { path: 'pegawai-sk-reimburse-perjalanan-dinas', loadChildren : () => import('./master-pegawai-sk.module').then(m => m.PegawaiSKReimburseModule) },
    { path: 'pegawai-sk-reimburse-reimburs', loadChildren : () => import('./master-pegawai-sk.module').then(m => m.PegawaiSKReimburseModule) },
    { path: 'pegawai-sk-reimburse-reimburs-perjalanan-dinas', loadChildren : () => import('./master-pegawai-sk.module').then(m => m.PegawaiSKReimburseModule) },
    { path: 'pegawai-sk-tidak-absen-jadwal', loadChildren : () => import('./master-pegawai-sk.module').then(m => m.PegawaiSkTidakAbsenModule) },
    { path: 'pegawai-sk-status-revtab-cuti', loadChildren : () => import('./master-pegawai-sk.module').then(m => m.PegawaiSkStatusRevtabModule) },
    { path: 'pegawai-sk-waktu-kerja-lembur', loadChildren : () => import('./master-pegawai-sk.module').then(m => m.PegawaiSkWaktuKerjaModule) },
    { path: 'pegawai-sk-payroll-by-status-lembur', loadChildren : () => import('./master-pegawai-sk.module').then(m => m.PegawaiSkPayrollByStatusModule) },
    { path: 'pegawai-sk-loan-pinjaman', loadChildren : () => import('./master-pegawai-sk.module').then(m => m.PegawaiSKLoanModule) },
    { path: 'pegawai-sk-status-revtab-phk', loadChildren : () => import('./master-pegawai-sk.module').then(m => m.PegawaiSkStatusRevtabModule) },
    { path: 'profile-sk-asuransi', loadChildren : () => import('./master-profile-sk.module').then(m => m.ProfileSKAsuransiModule) },  
    { path: 'master-pegawai-sk-asuransi-asuransi', loadChildren : () => import('./master-pegawai-sk.module').then(m => m.MasterPegawaiSkAsuransiModule) },
    { path: 'pegawai-sk-pajak-pajak', loadChildren : () => import('./master-pegawai-sk.module').then(m => m.PegawaiSkPajakModule) },
    { path: 'pegawai-sk-hitung-payroll-penggajian', loadChildren : () => import('./master-pegawai-sk.module').then(m => m.MasterPegawaiSkHitungPayrollModule) },
    { path: 'profile-sk-komponen-gaji-penggajian', loadChildren : () => import('./master-profile-sk.module').then(m => m.ProfileSkKomponenGajiModule) },
    { path: 'profile-sk-harga-poin', loadChildren : () => import('./master-profile-sk.module').then(m => m.ProfileSkHargaPoinModule) },
    { path: 'pegawai-sk-hitung-payroll-thr', loadChildren : () => import('./master-pegawai-sk.module').then(m => m.MasterPegawaiSkHitungPayrollModule) },
    { path: 'pegawai-sk-hitung-payroll-bonus', loadChildren : () => import('./master-pegawai-sk.module').then(m => m.MasterPegawaiSkHitungPayrollModule) },
    { path: 'pegawai-sk-status-revtab-pensiun', loadChildren : () => import('./master-pegawai-sk.module').then(m => m.PegawaiSkStatusRevtabModule) },
    { path: 'pegawai-sk-status-pensiun-new', loadChildren : () => import('./master-pegawai-sk.module').then(m => m.PegawaiSkStatusPensiunNewModule)},
    { path: 'pegawai-sk-status-cuti-new', loadChildren : () => import('./master-pegawai-sk.module').then(m => m.PegawaiSkStatusCutiNewModule)},
    { path: 'pegawai-sk-hitung-payroll-pesangon', loadChildren : () => import('./master-pegawai-sk.module').then(m => m.MasterPegawaiSkHitungPayrollModule) },
    { path: 'pegawai-sk-status-dinas', loadChildren : () => import('./master-pegawai-sk.module').then(m => m.PegawaiSkStatusDinasModule)},
    { path: 'pegawai-sk-hitung-komponen-ketidakhadiran', loadChildren : () => import('./master-pegawai-sk.module').then(m => m.PegawaiSkHitungKomponenKetidakhadiranModul)},
    { path: 'pegawai-sk-hitung-komponen-keterlambatan', loadChildren : () => import('./master-pegawai-sk.module').then(m => m.PegawaiSkHitungKomponenKeterlambatanModul)},

  
];