import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MenuItem } from 'primeng/primeng';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-perubahan-pinjaman',
  templateUrl: './menu-perubahan-pinjaman.component.html',
  styleUrls: ['./menu-perubahan-pinjaman.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MenuPerubahanPinjamanComponent implements OnInit {
  items: MenuItem[];
  activeIndex: number = 0;
  link  = [
    'daftar-kirim-pengajuan-hold-pinjaman-v2',
    'daftar-pengajuan-hold-pinjaman-v2',
    'daftar-terima-pengajuan-hold-pinjaman-v2'
  ];
  constructor(
    private route: Router
  ) { }

  ngOnInit() {
    let urlCurrent = this.route.url;
    for (let i = 0; i < this.link.length; i++) {
        if ( urlCurrent == '/' + this.link[i] ) {
            this.activeIndex = i;
            break;
        }
    }
    // this.activeIndex = parseInt(localStorage.getItem('perubahan-pinjaman-menu'));
    this.items = [
      {
          label: 'Pengajuan Perubahan Pinjaman',
          command: (event: any) => {
              this.activeIndex = 0;
              this.route.navigate(['daftar-kirim-pengajuan-hold-pinjaman-v2']);
              localStorage.setItem('perubahan-pinjaman-menu', String(this.activeIndex));
          }
      },
      {
          label: 'Daftar Pengajuan Perubahan Pinjaman',
          command: (event: any) => {
              this.activeIndex = 1;
              this.route.navigate(['daftar-pengajuan-hold-pinjaman-v2']);
              localStorage.setItem('perubahan-pinjaman-menu', String(this.activeIndex));
          }
      },
      {
        label: 'Daftar Terima Pengajuan Perubahan Pinjaman',
        command: (event: any) => {
            this.activeIndex = 2;
            this.route.navigate(['daftar-terima-pengajuan-hold-pinjaman-v2']);
            localStorage.setItem('perubahan-pinjaman-menu', String(this.activeIndex));
        }
    }

      ];
  }

}
