import { Component, OnInit, OnDestroy } from '@angular/core';
import { SelectItem } from 'primeng/primeng';
import { TranslateService } from '@ngx-translate/core';
import { HttpJasmed, PreviousRouteDetector, Authentication, AuthGuard, SettingsService, AlertService, InfoService, Configuration, LangToFlag, SettingInfo } from '../../../global'; 
import { Router } from '@angular/router';

import 'rxjs/add/operator/timeout'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

    disUsername: boolean;
    disPassword: boolean;

    state : SettingInfo;

    perusahaan : string;
    modul : string;
    unitKerja : string;
    namalokasi : string;

    model: any = {};
    loading: boolean = false;
    returnUrl: string;

    profile: SelectItem[];
    profileA = [];
    profileTerpilih : any;

    modApp: SelectItem[];
    modAppA = [];
    modAppImg = [];
    modAppTerpilih : any;

    showModul  : boolean = false;

    lokasi: SelectItem[];
    lokasiA = [];
    lokasiTerpilih : any;
    
    ruangan: SelectItem[];
    ruanganA = [];
    ruanganTerpilih : any;

    bahasa: SelectItem[];
    bahasaTerpilih: string = 'id';

    pilProfile : boolean = false;
    pilModApp : boolean = false;
    pilLokasi : boolean = false;
    pilRuangan : boolean = false;

    pilAll : boolean = false;
    pilLanjut : boolean = false;

    pilDisProfile: boolean = false;

    urlRes : string;

    oData : any;

    sudahLogin : boolean;

    koneksi : string = 'Server sibuk, tidak dapat melayani permintaan, silahkan ulangi.';
    userPassPil : string = 'Login gagal, nama pengguna atau kata sandi salah.';
    
    imgLoading : string = 'data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH'
                          +'/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAAC'
                          + 'wAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkY'
                          + 'DAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRV'
                          + 'saqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMl'
                          + 'FYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAA' 
                          + 'ABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI'
                          + '5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8'
                          + 'pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4'
                          + 'CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi6'
                          + '3P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAA'
                          + 'ALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1Yh'
                          + 'iCnlsRkAAAOwAAAAAAAAAAAA==';

  	constructor(private route: PreviousRouteDetector,
        private router: Router,
        private translate: TranslateService,
        private authentication: Authentication,
        private authGuard: AuthGuard,
        private alert: AlertService,
        private info: InfoService,
        private httpService: HttpJasmed,
        private settingsService: SettingsService) {

        this.bahasa = [];
        this.bahasa.push({label : 'Bahasa Indonesia', value: 'id'});
        this.bahasa.push({label : 'English', value: 'en'});
        this.urlRes = Configuration.get().resourceFile + '/image/show/'; 
    }

    ngOnDestroy(){
      
    }

    ngOnInit() {
      this.model = {};
      this.disUsername = false;
      this.disPassword = false;
      this.returnUrl = localStorage.getItem('session.url')
      if ( this.returnUrl === undefined ||  this.returnUrl === null){
        this.returnUrl = localStorage.getItem('last.url')
        if ( this.returnUrl === undefined ||  this.returnUrl === null){
          this.returnUrl = this.route.getPreviousUrl();
          if (this.returnUrl == '/viewer-absensi'){
            this.returnUrl = '/'
          }
        }
      }  else {
        localStorage.setItem('last.url', this.returnUrl)
        localStorage.removeItem('session.url')
      } 

      

      this.pilAll = false;
      this.sudahLogin = false;
      if (this.authGuard.isLogin()) {
          this.sudahLogin = true;
      }  
    }

    keHalamanUtama(){
      localStorage.removeItem('last.url');
      this.router.navigate([this.returnUrl]);     
    }

    classBahasa(lang:string){
      return "flag-icon flag-icon-" + LangToFlag.flags[lang];
    }

    pilihBahasa() {
      this.translate.use(this.bahasaTerpilih.toString());
      Configuration.lang = this.bahasaTerpilih.toString();
    }

    succes(){
      this.authGuard.checkLogin();
      
      this.httpService.get(Configuration.get().authLogin + '/menu/dinamic').subscribe(res => {
        this.authGuard.isLogin();
        this.settingsService.setCurrentMenu(res.data.menuUtama);        
        this.settingsService.setCurrentURL(res.data.listUrl);
        this.settingsService.setCurrentLabel(res.data.namaObjek);        
        this.info.hide();
//        this.pilAll = false;
        this.pulih();
        this.send_message_to_sw();
      },
      error => {
        this.clearStorage();
        //localStorage.clear();
//        localStorage.removeItem('user.mneu');
        this.authGuard.setUserDto(null);
        this.authGuard.isLogin();

        this.alert.warn("Peringatan", "Menu gagal diload dari server, silahkan coba lagi.");

        //this.pilAll = false;
        //this.pulih();
      });
    }

    del_message_to_sw(){
      if ('serviceWorker' in navigator && navigator.serviceWorker.controller !== undefined && navigator.serviceWorker.controller !== null) {
        navigator.serviceWorker.controller.postMessage(JSON.stringify({kdProfile: -1000}));
      }
     }

     
    send_message_to_sw(){
      let msg  = {
        kdProfile : this.authGuard.getUserDto().pegawai.kdProfile,
        kdPegawai :  this.authGuard.getUserDto().kdPegawai,
        kdRuangan : this.authGuard.getUserDto().kdRuangan,
        modulAplikasiId: this.authGuard.getUserDto().kdModulAplikasi,
        kdJabatan : this.authGuard.getUserDto().pegawai.kdJabatan
      }
      if ('serviceWorker' in navigator && navigator.serviceWorker.controller !== undefined && navigator.serviceWorker.controller !== null) {
        navigator.serviceWorker.controller.postMessage(JSON.stringify(msg));
      }
    }

    clearStorage(){
      this.del_message_to_sw();
      sessionStorage.clear();
      localStorage.clear();
    }

    pulih(){
      this.state = {
          profile : this.profile,
          profileA : this.profileA,
          profileTerpilih : this.profileTerpilih,

          modApp : this.modApp,
          modAppA : this.modAppA,
          modAppTerpilih : this.modAppTerpilih,

          lokasi : this.lokasi,
          lokasiA : this.lokasiA,
          lokasiTerpilih : this.lokasiTerpilih,

          ruangan : this.ruangan,
          ruanganA : this.ruanganA,
          ruanganTerpilih : this.ruanganTerpilih
        };
        this.settingsService.setSettingInfo(this.state);
//        this.appMenu.loadMenu(this.settingsService.getCurrentMenu());
        this.keHalamanUtama();
    }

    kembali() {
      this.pilAll = false;
    }

    batalLogin() {
      this.ngOnInit();
      this.lokasiTerpilih = null;
      this.ruanganTerpilih = null;
    }

    buildProfiles(data : any){
      this.profile = [];
      for (let i=0; i<data.length; i++){
        this.profile.push({label : data[i].namaLengkap, value: data[i].kdProfile}); 
      }
      this.profileTerpilih = data[0].kdProfile;
    }

    ///////////////

    pilihModApp(){
      let data = this.modAppImg;
      this.modAppImg = [];
      let selectCls : string;
      for (let i=0; i<data.length; i++){

      if (data[i].kdModulAplikasi == this.modAppTerpilih){
        selectCls = "borderMod";
      } else if (i == 0 && (this.modAppTerpilih == undefined || this.modAppTerpilih == null)){
        selectCls = "borderMod";
      } else {  
        selectCls =  "borderNone";
      }

      this.modAppImg[i] = {
          modulAplikasi : data[i].modulAplikasi,
          modulIconImage : data[i].modulIconImage,
          kdModulAplikasi : data[i].kdModulAplikasi,
          classBorder : selectCls
        };
      }  
    }

    buildModApps(data : any){
      this.modApp = [];
      this.modAppImg = [];
      
      let selectCls : string;

      for (let i=0; i<data.length; i++){
        this.modApp.push({label : data[i].modulAplikasi, value: data[i].kdModulAplikasi}); 
        
        if (data[i].kdModulAplikasi == this.modAppTerpilih){
          selectCls = "borderMod";
        } else if (i == 0 && (this.modAppTerpilih == undefined || this.modAppTerpilih == null)){
          selectCls = "borderMod";
        } else {  
          selectCls =  "borderNone";
        }

        this.modAppImg[i] = {
            modulAplikasi : data[i].modulAplikasi,
            kdModulAplikasi : data[i].kdModulAplikasi,
            modulIconImage : this.urlRes + data[i].modulIconImage,
            classBorder : selectCls
        };
      }      
      this.modAppTerpilih = data[0].kdModulAplikasi;
    }

    /////////////////////////////

    pilihPerusahaanDropdown(){
      if (this.profileTerpilih !== null || this.profileTerpilih !== undefined) {
        this.selectProfile();
      }
    }


    ///////////////

    pilihLokasiDropdown() {
      if (this.lokasiTerpilih !== null || this.lokasiTerpilih !== undefined) {
        this.selectLokasi();
      }
    }

    buildLokasis(data : any){
      this.lokasi = [];
      for (let i=0; i<data.length; i++){
        this.lokasi.push({label : data[i].namaRuangan, value: data[i].kdRuangan}); 
      }      
      // this.lokasiTerpilih = data[0].kdRuangan;
    }
    
    ///////////////

    pilihRuanganDropdown() {
      if (this.ruanganTerpilih !== null || this.ruanganTerpilih !== undefined) {
        this.selectRuangan();
      }
    }

    buildRuangans(data : any){
      this.ruangan = [];
      for (let i=0; i<data.length; i++){
        this.ruangan.push({label : data[i].namaRuangan, value: data[i].kdRuangan}); 
      }      
      // this.ruanganTerpilih = data[0].kdRuangan;
    }

    ////////////////

    trackByFn(index, item){
      return index;
    }

    masuk() {

      if (this.pilLanjut){
        this.authGuard.setUserDto(this.oData);

        this.perusahaan = this.oData.profile.namaLengkap;
        
        this.profileA = this.oData.profiles;
        this.modAppA = this.oData.modulAplikasis;
        this.lokasiA = this.oData.lokasis;    
        this.ruanganA = this.oData.ruangans;    

        // this.profileTerpilih = this.profileA[0].kdProfile;
        // this.modAppTerpilih = this.modAppA[0].kdModulAplikasi;
        // this.lokasiTerpilih = this.lokasiA[0].kdRuangan;
        // this.ruanganTerpilih = this.ruanganA[0].kdRuangan;
        this.succes();
      }
    }

    checkData(data : any){
      

      // this.pilProfile  = false;
      this.pilModApp  = false;

      // if (this.profileTerpilih === null || this.profileTerpilih === undefined) {
      //   this.pilProfile  = false;
      // } else {
      //   this.pilProfile  = true;
      // }

      if (this.lokasiTerpilih === null || this.lokasiTerpilih === undefined) {
        this.pilLokasi  = false;
      } else {
        this.pilLokasi  = true;
      }

      if (this.ruanganTerpilih === null || this.ruanganTerpilih === undefined) {
        this.pilRuangan = false;
      } else {
        this.pilRuangan  = true;
      }
      

       this.loading = false;
       if (data.token != undefined && data.token != null && !this.pilAll) {

          this.pilProfile  = true;
          this.pilModApp  = true;
          this.pilLokasi  = true;
          this.pilRuangan  = true;
          this.showModul = false;
         
        
          this.authGuard.setUserDto(data);

          this.perusahaan = data.profile.namaLengkap;

          this.profileA = data.profiles;
          this.modAppA = data.modulAplikasis;
          this.lokasiA = data.lokasis;    
          this.ruanganA = data.ruangans;    

          this.modAppTerpilih = this.modAppA[0].kdModulAplikasi;
          this.profileTerpilih = this.profileA[0].kdProfile;
          this.lokasiTerpilih = this.lokasiA[0].kdRuangan;
          this.ruanganTerpilih = this.ruanganA[0].kdRuangan;

          this.succes();              
       } else if (data != undefined && data != null) {  
          this.pilAll = true;
          this.oData = data;              
          //this.authGuard.setUserDto(data);
          this.profileA = this.oData.profiles;

          if (this.profileA.length > 1 && (this.oData.idProfile == null)){
            // this.pilProfile = true;  
            this.pilDisProfile = false;
            this.profileTerpilih = this.profileA[0].kdProfile;
            this.buildProfiles(this.profileA);
            this.pilihPerusahaanDropdown();
            // nah di sini set unit sama dropdown build ruangan
          } else {
             // nah di sini set unit sama dropdown build ruangan

            this.pilDisProfile = true;
            // this.pilProfile = false;  

            if (this.oData.profile === undefined || this.oData.profile === null){
                for (let i =0; i < this.oData.profiles.length; i++ ){
                  if (this.oData.profiles[i].kdProfile == this.oData.idProfile){
                    this.oData.profile = this.oData.profiles[i];
                  }
                }
            }

            this.perusahaan = this.oData.profile.namaLengkap;
            this.modAppA = this.oData.modulAplikasis;
            if (this.modAppA.length > 1 && (this.oData.idModulAplikasi == null)){
              this.pilModApp = true;       
              this.modAppTerpilih = this.modAppA[0].kdModulAplikasi;
              this.buildModApps(this.modAppA );                   
            } else {

              this.pilModApp = false;  

              if (this.oData.modulAplikasi === undefined || this.oData.modulAplikasi === null){
                for (let i =0; i < this.oData.modulAplikasis.length; i++ ){
                  if (this.oData.modulAplikasis[i].kdModulAplikasi == this.oData.idModulAplikasi){
                    this.oData.modulAplikasi = this.oData.modulAplikasis[i];
                  }
                }
              }
              this.modul = this.oData.modulAplikasi.modulAplikasi;
              this.lokasiA = this.oData.lokasis;

              this.showModul = this.oData.modulAplikasis.length > 1;

              if (this.lokasiA.length > 1 && (this.oData.idLokasi == null)){
                this.pilLokasi = true;
                if ( this.lokasiTerpilih === null || this.lokasiTerpilih === undefined ) {
                  this.lokasiTerpilih = this.lokasiA[0].kdRuangan;
                }
                this.buildLokasis(this.lokasiA );
                // disini panggil dropdown eventnya
                this.pilihLokasiDropdown();
              } else {

                this.buildLokasis(this.lokasiA );
                this.pilLokasi = false;  

                // if (this.oData.lokasi === undefined || this.oData.lokasi === null){
                  for (let i =0; i < this.oData.lokasis.length; i++ ){
                    if (this.oData.lokasis[i].kdRuangan == this.oData.idLokasi){
                      this.oData.lokasi = this.oData.lokasis[i];
                    }
                  }
                // }

                this.namalokasi = this.oData.lokasi.namaRuangan;
                this.ruanganA = this.oData.ruangans;    
                if (this.ruanganA.length > 1 && (this.oData.idRuangan == null)){
                  this.pilRuangan = true;
                  // if ( this.ruanganTerpilih === null || this.ruanganTerpilih === undefined ) {
                    this.ruanganTerpilih = this.ruanganA[0].kdRuangan;
                  // }
                  this.buildRuangans(this.ruanganA);
                  this.pilihRuanganDropdown();
                } else {
                  this.buildRuangans(this.ruanganA);
                  // this.pilihRuanganDropdown();
                  this.unitKerja = this.oData.ruangan.namaRuangan;
                  this.pilRuangan = false;
                  this.pilLanjut = true;
                }
              }                      
            }
          }
       } else {
          this.alert.warn("Peringatan", this.userPassPil);
        }
    }

    keyboardLogin(event, f: any){

      if (this.loading || !f.form.valid){
        return;
      }

       if(event.keyCode == 13) {
         this.loginUser();
       }
    }

    loginUserCek(){
      const username = this.model.username;
      const password = this.model.password;
      // if (username !== undefined && password !== undefined) {
      //   this.loginUserVersi2(username, password);
      // }

    }

  	loginUser() {
      

      this.info.hide();
      console.log("loginUser");

      this.authGuard.setUserDto(null);
      this.loading = true;
      this.authentication
          .login(this.model.username, this.model.password)
          .timeout(5000)
          .subscribe(
              data => {

                if ( data.idProfile !== null ) {
                  this.pilProfile = false;
                } else {
                  this.pilProfile = true;
                }

                this.checkData(data);
                this.disUsername = true;
                this.disPassword = true;
              },
              error => {
                  this.loading = false;                
                  if (error.name == 'TimeoutError'){
                    this.alert.warn("Peringatan", this.koneksi);    
                  } else {
                    //let errorMessage = JSON.parse(error._body)
                    let errorMessage = error.error;
                    let errorText = '';
                    if (errorMessage.statusCode == "501" || errorMessage.statusCode == 501) {
                      for (let i=0;i<errorMessage.errors.length;i++){
                        errorText += errorMessage.errors[i].error;
                      }
                      this.alert.warn("Peringatan", errorText);                   
                    } else {
                      this.alert.warn("Peringatan", this.koneksi);                      
                    }
                }
              }
           );
  	}

    selectProfile(){
      this.info.hide();  
      console.log("selectProfile");

      this.oData.idProfile = this.profileTerpilih*1;   
      this.oData.kdProfile = this.profileTerpilih*1; 

      this.oData.idModulAplikasi = null;
      this.oData.kdModulAplikasi = null;

      this.oData.idLokasi = null;
      this.oData.kdLokasi = null;

      this.oData.idRuangan = null;
      this.oData.kdRuangan = null;

      this.loading = true;

      this.authentication    
          .logProfile(this.oData)
          .timeout(8000)
          .subscribe(
              data => {
                this.checkData(data);
                // this.masuk();
              },
              error => {
                  this.loading = false;                
                  if (error.name == 'TimeoutError'){
                    this.alert.warn("Peringatan", this.koneksi);    
                  } else {
                    let errorMessage = JSON.parse(error._body)
                    let errorText = '';
                    if (errorMessage.statusCode == "501" || errorMessage.statusCode == 501) {
                      for (let i=0;i<errorMessage.errors.length;i++){
                        errorText += errorMessage.errors[i].error;
                      }
                      this.alert.warn("Peringatan", errorText);                   
                    } else {
                      this.alert.warn("Peringatan", this.koneksi);                      
                    }
                }
              }
           );
    }  

    selectModApp(){
      this.info.hide();
      console.log("selectModApp");

      this.oData.idModulAplikasi = this.modAppTerpilih;    
      this.oData.kdModulAplikasi = this.modAppTerpilih; 

      this.oData.idLokasi = null;
      this.oData.kdLokasi = null;
      this.loading = true;

      this.authentication    
          .logModulApp(this.oData)
          .timeout(5000)
          .subscribe(
              data => {
                this.checkData(data);
                this.masuk();
              },
              error => {
                  this.loading = false;                
                  if (error.name == 'TimeoutError'){
                    this.alert.warn("Peringatan", this.koneksi);    
                  } else {
                    let errorMessage = JSON.parse(error._body)
                    let errorText = '';
                    if (errorMessage.statusCode == "501" || errorMessage.statusCode == 501) {
                      for (let i=0;i<errorMessage.errors.length;i++){
                        errorText += errorMessage.errors[i].error;
                      }
                      this.alert.warn("Peringatan", errorText);                   
                    } else {
                      this.alert.warn("Peringatan", this.koneksi);                      
                    }
                }
              }
           );
    }  

    selectLokasi(){
      this.info.hide();
      console.log("selectLokasi");

      this.oData.idLokasi = this.lokasiTerpilih;
      this.oData.kdLokasi = this.lokasiTerpilih;

      this.oData.idRuangan = null;
      this.oData.kdRuangan = null;
      
      this.loading = true;

      this.authentication    
          .logLokasi(this.oData)
          .timeout(8000)
          .subscribe(
              data => {
                this.checkData(data);
               // this.masuk();
              },
              error => {
                  this.loading = false;                
                  if (error.name == 'TimeoutError'){
                    this.alert.warn("Peringatan", this.koneksi);    
                  } else {
                    let errorMessage = JSON.parse(error._body)
                    let errorText = '';
                    if (errorMessage.statusCode == "501" || errorMessage.statusCode == 501) {
                      for (let i=0;i<errorMessage.errors.length;i++){
                        errorText += errorMessage.errors[i].error;
                      }
                      this.alert.warn("Peringatan", errorText);                   
                    } else {
                      this.alert.warn("Peringatan", this.koneksi);                      
                    }
                }
              }
           );
    }

    selectRuangan(){
      this.info.hide();
      console.log("selectRuangan");

      this.oData.idRuangan = this.ruanganTerpilih;
      this.oData.kdRuangan = this.ruanganTerpilih;
      this.loading = true;

      this.authentication    
          .logRuangan(this.oData)
          .timeout(5000)
          .subscribe(
              data => {
                this.checkData(data);
               // this.masuk();
              },
              error => {
                  this.loading = false;                
                  if (error.name == 'TimeoutError'){
                    this.alert.warn("Peringatan", this.koneksi);    
                  } else {
                    let errorMessage = JSON.parse(error._body)
                    let errorText = '';
                    if (errorMessage.statusCode == "501" || errorMessage.statusCode == 501) {
                      for (let i=0;i<errorMessage.errors.length;i++){
                        errorText += errorMessage.errors[i].error;
                      }
                      this.alert.warn("Peringatan", errorText);                   
                    } else {
                      this.alert.warn("Peringatan", this.koneksi);                      
                    }
                }
              }
           );
    }
}
