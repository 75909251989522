import { Injectable, forwardRef, Inject } from '@angular/core';
import { HttpJasmed } from '../../../global';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import * as $ from 'jquery';

@Injectable()
export class ReportService {

    private subject = new Subject<any>();
    private subjectMsg = new Subject<any>();

    display:boolean;

    private options:any;
    private myPDF:any;
    private urlPDF:string;

    constructor( @Inject(forwardRef(() => HttpJasmed)) private http: HttpJasmed) {}

    getInfoLaporan(): Observable<any> {
        return this.subject.asObservable();
    }

    getTitleLaporan(): Observable<any> {
        return this.subjectMsg.asObservable();
    }

    openLaporan(title : string) {
        this.subject.next(true);
        this.subjectMsg.next(title);
    }
 
    tutupLaporan() {
        this.subject.next(false);
    }

    private embedPDF(urlPDF: string, embed:boolean, height:number){
        var w = $(window).height(); 
        var h = w - height;

//        $("#report-pdf-embed").height();

//        let me = this;

        setTimeout(() => {
            document.getElementById("report-pdf-embed").innerHTML = '<img src="assets/layout/images/buble2.gif" />' 
            this.http.getForced(urlPDF).subscribe(res => {
                document.getElementById("report-pdf-embed").style.height = h + "px";
                var file = new Blob( [res], {type: 'application/pdf'});
                var url = window.URL.createObjectURL(file)
                document.getElementById("report-pdf-embed").innerHTML = '<embed src="' + url + '#view=FitH&toolbar=1" type="application/pdf" width="100%" height="100%"></embed>';
                console.log(document.getElementById("report-pdf-embed").innerHTML);
            });
        }, 500);
    }

    private embedPDFGeneric(urlPDF: string, data, embed:boolean, height:number){
        var w = $(window).height(); 
        var h = w - height;

//        $("#report-pdf-embed").height();

        let me = this;

        setTimeout(function(){
            me.http.genericReport(urlPDF, data).subscribe(res => {
                document.getElementById("report-pdf-embed").style.height = h + "px";
                var file = new Blob( [res], {type: 'application/pdf'});
                var url = window.URL.createObjectURL(file)

                document.getElementById("report-pdf-embed").innerHTML = '<embed src="' + url + '#view=FitH&toolbar=1" type="application/pdf" width="100%" height="100%"></embed>';

                // $("#report-pdf").innerHTML = '<iframe src="' + url + '#zoom=100&view=FitH" width="100%" height="100%">'
                //     'Tidak bisa menampilkan PDF <a href="/pdf/sample-3pp.pdf">Download PDF</a>' +
                //     '</iframe>';

                console.log(document.getElementById("report-pdf-embed").innerHTML);

            });
        }, 500);
    }

    private embedPDFGenericOnly(urlPDF: string, data, embed:boolean, height:number){
        var w = $(window).height(); 
        var h = w - height;

//        $("#report-pdf-embed").height();

        let me = this;

        setTimeout(function(){
            me.http.genericReport(urlPDF, data).subscribe(res => {
                //document.getElementById("report-pdf-embed").style.height = h + "px";
                var file = new Blob( [res], {type: 'application/pdf'});
                var url = window.URL.createObjectURL(file)

                //document.getElementById("report-pdf-embed").innerHTML = '<embed src="' + url + '#view=FitH&toolbar=1" type="application/pdf" width="100%" height="100%"></embed>';

                // $("#report-pdf").innerHTML = '<iframe src="' + url + '#zoom=100&view=FitH" width="100%" height="100%">'
                //     'Tidak bisa menampilkan PDF <a href="/pdf/sample-3pp.pdf">Download PDF</a>' +
                //     '</iframe>';

                //console.log(document.getElementById("report-pdf-embed").innerHTML);

            });
        }, 500);
    }

    private embedPDFGenericXLSX(urlPDF: string, data, embed:boolean, height:number){
        var w = $(window).height(); 
        var h = w - height;

//        $("#report-pdf-embed").height();

        let me = this;

        setTimeout(function(){
            me.http.genericReport(urlPDF, data).subscribe(res => {
                document.getElementById("report-pdf-embed").style.height = h + "px";
                var file = new Blob( [res], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                var url = window.URL.createObjectURL(file)

                document.getElementById("report-pdf-embed").innerHTML = '<embed src="http://docs.google.com/gview?url=' + url + '&embedded=true#view=FitH&toolbar=1" type="application/vnd.ms-excel" width="100%" height="100%"></embed>';

                // $("#report-pdf").innerHTML = '<iframe src="' + url + '#zoom=100&view=FitH" width="100%" height="100%">'
                //     'Tidak bisa menampilkan PDF <a href="/pdf/sample-3pp.pdf">Download PDF</a>' +
                //     '</iframe>';

                console.log(document.getElementById("report-pdf-embed").innerHTML);

            });
        }, 500);
    }

    showEmbedXLSXReport(urlPDF:string, title:string = 'Report', data: any = 'normal', height:number = 130){
        this.openLaporan(title);
        this.embedPDFGenericXLSX(urlPDF, data, false, height);    
    }     

    showEmbedPDFReport(urlPDF:string, title:string = 'Report', data: any = 'normal', height:number = 130){
        this.openLaporan(title);
        if (data === undefined || data === null || data === 'normal'){
            this.embedPDF(urlPDF, false, height);            
        } else {
            this.embedPDFGeneric(urlPDF, data, false, height);    
        }
    }
    
    showEmbedPDFReportDownloadOnly(urlPDF:string, data: any = 'normal', height:number = 200){
        //this.openLaporan(title);
        if (data === undefined || data === null || data === 'normal'){
            this.embedPDF(urlPDF, false, height);            
        } else {
            this.embedPDFGenericOnly(urlPDF, data, false, height);    
        }
    } 




    ///////////////////////////////

    ///////////////////////////////

    

    private units = ['', 'ribu', 'juta', 'milyar', 'triliun', 'quadriliun', 'quintiliun', 'sextiliun', 'septiliun', 'oktiliun', 'noniliun', 'desiliun', 'undesiliun', 'duodesiliun', 'tredesiliun', 'quattuordesiliun', 'quindesiliun', 'sexdesiliun', 'septendesiliun', 'oktodesiliun', 'novemdesiliun', 'vigintiliun']
    maxIndex = this.units.length - 1


    private digitToUnit (digit) {
      const curIndex = digit / 3
      return curIndex <= this.maxIndex ? this.units[curIndex] : this.units[this.maxIndex]
    }
    
    numbers = ['', 'satu', 'dua', 'tiga', 'empat', 'lima', 'enam', 'tujuh', 'delapan', 'sembilan']
    
    private numberToText (index) {
      return this.numbers[index] || ''
    }
    
    private terbilang = (angka) => {
      const angkaLength   = angka.length
      const angkaMaxIndex = angkaLength - 1
    
      // Angka Nol
      if (angkaMaxIndex === 0 && Number(angka[0]) === 0) {
        return 'nol'
      }
    
      let space = ''
      let result = ''
    
      let i = 0
      while (i !== angkaLength) {
    
        const digitCount = angkaMaxIndex - i
        const modGroup = digitCount % 3 // [2,1,0]
        const curAngka = Number(angka[i])
    
        if (digitCount === 3 && curAngka === 1 && (i === 0 || 
          (Number(angka[i - 2]) === 0 && Number(angka[i - 1]) === 0))) {
          /* Angka Seribu */
          result += `${space}seribu`
        } else {
          if (curAngka !== 0) {
            if (modGroup === 0) {
              /* Angka Satuan Bukan Nol */
              result += `${space}${this.numberToText(curAngka)}${(i === angkaMaxIndex ? '' : ' ')}${this.digitToUnit(digitCount)}`
            } else if (modGroup === 2) {
              /* Angka Ratusan */
              if (curAngka === 1) {
                result += `${space}seratus`
              } else {
                result += `${space}${this.numberToText(curAngka)} ratus`
              }
            } else {
              /* Angka Sepuluh dan Belasan */
              if (curAngka === 1) {
                i++ // Skip Next Angka
                const nextAngka = Number(angka[i])
                if (nextAngka === 0) {
                  result += `${space}sepuluh`
                  /* Proses Next Angka Sekarang */
                  if (digitCount !== 1 && (Number(angka[i - 2]) !== 0 || Number(angka[i - 1]) !== 0)) {
                    result += ` ${this.digitToUnit(digitCount - 1)}`
                  }
                } else {
                  if (nextAngka === 1) {
                    result += `${space}sebelas`
                  } else {
                    result += `${space}${this.numberToText(nextAngka)} belas`
                  }
                  /* Proses Next Angka Sekarang */
                  if (digitCount !== 1) {
                    result += ` ${this.digitToUnit(digitCount - 1)}`
                  }
                }
              } else {
                /* Angka Puluhan */
                result += `${space}${this.numberToText(curAngka)} puluh`
              }
            }
          } else {
            /* Angka Satuan Nol */
            if (modGroup === 0 && (Number(angka[i - 2]) !== 0 || Number(angka[i - 1]) !== 0) && digitCount !== 0) {
              result += ` ${this.digitToUnit(digitCount)}`
            }
          }
        }
    
        if (i <= 1) {
          space = ' '
        }
        i++
      }
    
      return result
    }
    
    private terbilangSatuSatu = (angka) => {
      return angka
        .split('')
        .map(angka => angka == 0 ? 'nol' : this.numberToText(angka))
        .join(' ')
    }
    
    angkaTerbilang(target, settings={decimal: '.'}) {
      if (typeof target !== "string") target = String(target)
      if (target.indexOf(settings.decimal) > -1) {
        /* Dengan Desimal */
        target = target.split(settings.decimal)
        return `${this.terbilang(target[0])} koma ${this.terbilangSatuSatu(target[1])}`
      } else {
        /* Tanpa Desimal */
        return this.terbilang(target)
      }
    }

    titleCase(txt) {
      return txt[0].toUpperCase() + txt.substr(1).toLowerCase()
    }

}