
import * as pRedirect from './';
import { AuthGuard } from '../global';

export const routeRedirect = [
	{ canActivate: [AuthGuard], path: 'proses-registrasi-pegawai', component: pRedirect.RedirectProsesRegistrasiPegawaiComponent },
	{ canActivate: [AuthGuard], path: 'proses-penggajian', component: pRedirect.RedirectProsesPenggajianComponent },
	{ canActivate: [AuthGuard], path: 'proses-penggajian2', component: pRedirect.RedirectProsesPenggajianComponent },
	{ canActivate: [AuthGuard], path: 'menu-resign', component: pRedirect.RedirectMenuResignComponent },
	{ canActivate: [AuthGuard], path: 'menu-validasi-sip-str', component: pRedirect.RedirectMenuValidasiSipStrComponent },
	{ canActivate: [AuthGuard], path: 'menu-upload', component: pRedirect.RedirectMenuUploadComponent },
	{ canActivate: [AuthGuard], path: 'menu-evaluasi-jabatan', component: pRedirect.RedirectMenuEvaluasiJabatanComponent },
	{ canActivate: [AuthGuard], path: 'menu-reward', component: pRedirect.RedirectMenuRewardComponent },
	{ canActivate: [AuthGuard], path: 'menu-sanksi', component: pRedirect.RedirectMenuSanksiComponent },
	{ canActivate: [AuthGuard], path: 'menu-cuti', component: pRedirect.RedirectMenuCutiComponent },
	{ canActivate: [AuthGuard], path: 'menu-lembur', component: pRedirect.RedirectMenuLemburComponent },
	{ canActivate: [AuthGuard], path: 'menu-pembatalan-transaksi', component: pRedirect.RedirectPembatalanTransaksiComponent },
	{ canActivate: [AuthGuard], path: 'menu-phk', component: pRedirect.RedirectMenuPhkComponent },
	{ canActivate: [AuthGuard], path: 'menu-pensiun', component: pRedirect.RedirectMenuPensiunComponent },
	{ canActivate: [AuthGuard], path: 'menu-reimburs-perjalanan-dinas', component: pRedirect.RedirectMenuReimbursPerjalananDinasComponent },
	{ canActivate: [AuthGuard], path: 'menu-perjalanan-dinas', component: pRedirect.RedirectMenuPerjalananDinasComponent },
	{ canActivate: [AuthGuard], path: 'menu-perubahan-jadwal-kerja', component: pRedirect.RedirectMenuPerubahanJadwalKerjaComponent },
	{ canActivate: [AuthGuard], path: 'menu-perubahan-pinjaman', component: pRedirect.RedirectMenuPerubahanPinjamanComponent },
	{ canActivate: [AuthGuard], path: 'menu-pinjaman', component: pRedirect.RedirectMenuPinjamanComponent },
	{ canActivate: [AuthGuard], path: 'menu-reimburs', component: pRedirect.RedirectMenuReimbursComponent },
	{ canActivate: [AuthGuard], path: 'menu-orientasi-pegawai', component: pRedirect.RedirectMenuOrientasiPegawaiComponent },
	{ canActivate: [AuthGuard], path: 'menu-pemberian-tunjangan-kesejahteraan', component: pRedirect.RedirectMenuKesejahteraanComponent }

];
