// var vlocal = {
//   apiBackend          : '',
//   dataMaster          : /*'http://'+ window.location.hostname + ':8292', //*/'https://hris.bottis.co.id/dataMaster',
//   dataMasterNew       : /*'http://'+ window.location.hostname + ':8292', //*/'https://hris.bottis.co.id/dataMaster',

//   report              : /*'http://'+ window.location.hostname + ':8797', //*/'https://hris.bottis.co.id/authInfo',
//   smartReport         : /*'http://'+ window.location.hostname + ':8797', //*/'https://hris.bottis.co.id/smartReport',
//   authLogin           : /*'http://'+ window.location.hostname + ':8797', //*/'https://hris.bottis.co.id/authInfo',

//   dataHr1Mod1         : /*'http://'+ window.location.hostname + ':8393', //*/'https://hris.bottis.co.id/dataHr1Mod1',
//   dataHr1Mod2         : /*'http://'+ window.location.hostname + ':8393', //*/'https://hris.bottis.co.id/dataHr1Mod1',
//   dataHr2Mod1         : /*'http://'+ window.location.hostname + ':8393', //*/'https://hris.bottis.co.id/dataHr1Mod1',
//   dataHr2Mod2         : /*'http://'+ window.location.hostname + ':8393', //*/'https://hris.bottis.co.id/dataHr1Mod1',

//   dataHr2Mod3         : 'http://'+ window.location.hostname + ':9696', //*/'https://hris.bottis.co.id/dataHr2Mod3',
//   dataBSC             : /*'http://192.168.0.143:9898', //*/'https://hris.bottis.co.id/dataBSC',

//   dataBridging        : /*'http://192.168.0.143:9797', //*/ 'https://hris.bottis.co.id/dataBridging',

//   // dataBNI          : 'http://192.168.0.212:9933',


//   page                : 1,
//   rows                : 10,
//   headerToken         : 'x-auth-token',
//   menuDinamic         : true,
//   idleTimeOut         : 1440, // menit
//   menuStatic          :  [{
//           "label": "Dashboard",
//           "icon": "fa fa-fw fa-chevron-right",
//           "routerLink": ["/"]
//       }],
//   /// TIGA INI JGN PERNAH DIUBAH OKE.. HATURNUHUN
//   resourceFile        : /*'http://'+ window.location.hostname + ':8797', //*/'https://hris.bottis.co.id/authInfo',
//   resourceReport      : /*'http://'+ window.location.hostname + ':8797', //*/'https://hris.bottis.co.id/authInfo',
//   socketIO            : /*'http://192.168.0.143:2222'  //*/'https://hris.bottis.co.id'
// }

// export default vlocal;

// var vlocal = {
//   apiBackend          : '',
//   dataMaster          : /*'http://'+ window.location.hostname + ':8292', //*/'https://hris.rsgrandmed.com/dataMaster',
//   dataMasterNew       : /*'http://'+ window.location.hostname + ':8292', //*/'https://hris.rsgrandmed.com/dataMaster',

//   report              : /*'http://'+ window.location.hostname + ':8797', //*/'https://hris.rsgrandmed.com/authInfo',
//   smartReport         : /*'http://'+ window.location.hostname + ':8797', //*/'https://hris.rsgrandmed.com/smartReport',
//   authLogin           : /*'http://'+ window.location.hostname + ':8797', //*/'https://hris.rsgrandmed.com/authInfo',

//   dataHr1Mod1         : /*'http://'+ window.location.hostname + ':8393', //*/'https://hris.rsgrandmed.com/dataHr1Mod1',
//   dataHr1Mod2         : /*'http://'+ window.location.hostname + ':8393', //*/'https://hris.rsgrandmed.com/dataHr1Mod1',
//   dataHr2Mod1         : /*'http://'+ window.location.hostname + ':8393', //*/'https://hris.rsgrandmed.com/dataHr1Mod1',
//   dataHr2Mod2         : /*'http://'+ window.location.hostname + ':8393', //*/'https://hris.rsgrandmed.com/dataHr1Mod1',

//   dataHr2Mod3         : 'http://'+ window.location.hostname + ':9696', //*/'https://hris.rsgrandmed.com/dataHr2Mod3',
//   dataBSC             : /*'http://192.168.0.143:9898', //*/'https://hris.rsgrandmed.com/dataBSC',

//   dataBridging        : /*'http://192.168.0.143:9797', //*/ 'https://hris.rsgrandmed.com/dataBridging',

//   // dataBNI          : 'http://192.168.0.212:9933',


//   page                : 1,
//   rows                : 10,
//   headerToken         : 'x-auth-token',
//   menuDinamic         : true,
//   idleTimeOut         : 1440, // menit
//   menuStatic          :  [{
//           "label": "Dashboard",
//           "icon": "fa fa-fw fa-chevron-right",
//           "routerLink": ["/"]
//       }],
//   /// TIGA INI JGN PERNAH DIUBAH OKE.. HATURNUHUN
//   resourceFile        : /*'http://'+ window.location.hostname + ':8797', //*/'https://hris.rsgrandmed.com/authInfo',
//   resourceReport      : /*'http://'+ window.location.hostname + ':8797', //*/'https://hris.rsgrandmed.com/authInfo',
//   socketIO            : /*'http://192.168.0.143:2222'  //*/'https://hris.rsgrandmed.com'
// }


// export default vlocal;


// var vlocal = {
//   apiBackend: '',
//   dataMaster: /*'http://'+ window.location.hostname + ':8292', //*/'http://bdg2.jasamedika.com:2304/dataMaster',
//   dataMasterNew: /*'http://'+ window.location.hostname + ':8292', //*/'http://bdg2.jasamedika.com:2304/dataMaster',

//   report: /*'http://'+ window.location.hostname + ':8797', //*/'http://bdg2.jasamedika.com:2304/authInfo',
//   authLogin: /*'http://'+ window.location.hostname + ':8797', //*/'http://bdg2.jasamedika.com:2304/authInfo',
//   smartReport: /*'http://'+ window.location.hostname + ':8797', //*/ 'https://bdg2.jasamedika.com:2304/smartReport',
//   dataHr1Mod1: /*'http://'+ window.location.hostname + ':8393', //*/'http://bdg2.jasamedika.com:2304/dataHr1Mod1',
//   dataHr1Mod2: /*'http://'+ window.location.hostname + ':8393', //*/'http://bdg2.jasamedika.com:2304/dataHr1Mod1',
//   dataHr2Mod1: /*'http://'+ window.location.hostname + ':8393', //*/'http://bdg2.jasamedika.com:2304/dataHr1Mod1',
//   dataHr2Mod2: /*'http://'+ window.location.hostname + ':8393', //*/'http://bdg2.jasamedika.com:2304/dataHr1Mod1',



//   dataHr2Mod3         : /*'http://'+ window.location.hostname + ':9696', //*/'http://bdg2.jasamedika.com:2304/dataHr2Mod3',
//   dataBSC             : /*'http://192.168.0.143:9898', //*/'http://bdg2.jasamedika.com:2304/dataBSC',

//   dataBridging: /*'http://192.168.0.143:9797', //*/ 'http://bdg2.jasamedika.com:2304/dataBridging',

//   // dataBNI          : 'http://192.168.0.212:9933',

//   dataBridgingRemun   : 'http://bdg2.jasamedika.com:2304/service/remunerasi',

//   dataBridgingSavePgw : 'https://erp.transmedic.co.id/service/hris',

//   page: 1,
//   rows: 10,
//   headerToken: 'x-auth-token',
//   menuDinamic: true,
//   idleTimeOut: 1440, // menit
//   menuStatic: [{
//     "label": "Dashboard",
//     "icon": "fa fa-fw fa-chevron-right",
//     "routerLink": ["/"]
//   }],
//   /// TIGA INI JGN PERNAH DIUBAH OKE.. HATURNUHUN
//   resourceFile: /*'http://192.168.0.102:9797', //*/'http://bdg2.jasamedika.com:2304/authInfo',
//   resourceReport: /*'http://192.168.0.102:9797', //*/'http://bdg2.jasamedika.com:2304/authInfo',
//   socketIO: /*'http://192.168.0.143:2222'  //*/'http://bdg2.jasamedika.com:2304'
// }


// export default vlocal;

// var vlocal = {
//   apiBackend: '',
//   dataMaster: /*'http://'+ window.location.hostname + ':8292', //*/'http://192.168.5.68/dataMaster',
//   dataMasterNew: /*'http://'+ window.location.hostname + ':8292', //*/'http://192.168.5.68/dataMaster',

//   report: /*'http://'+ window.location.hostname + ':8797', //*/'http://192.168.5.68/authInfo',
//   authLogin: /*'http://'+ window.location.hostname + ':8797', //*/'http://192.168.5.68/authInfo',
//   smartReport: /*'http://'+ window.location.hostname + ':8797', //*/ 'https://192.168.5.68/smartReport',
//   dataHr1Mod1: /*'http://'+ window.location.hostname + ':8393', //*/'http://192.168.5.68/dataHr1Mod1',
//   dataHr1Mod2: /*'http://'+ window.location.hostname + ':8393', //*/'http://192.168.5.68/dataHr1Mod1',
//   dataHr2Mod1: /*'http://'+ window.location.hostname + ':8393', //*/'http://192.168.5.68/dataHr1Mod1',
//   dataHr2Mod2: /*'http://'+ window.location.hostname + ':8393', //*/'http://192.168.5.68/dataHr1Mod1',



//   dataHr2Mod3         : /*'http://'+ window.location.hostname + ':9696', //*/'http://192.168.5.68/dataHr2Mod3',
//   dataBSC             : /*'http://192.168.0.143:9898', //*/'http://192.168.5.68/dataBSC',

//   dataBridging: /*'http://192.168.0.143:9797', //*/ 'http://192.168.5.68/dataBridging',
// // export default vlocal;

//   // dataBNI          : 'http://192.168.0.212:9933',

//   dataBridgingRemun   : 'http://192.168.5.68/service/remunerasi',
//   dataBridgingSmartPresence: 'http://192.168.5.68/rsf-smart',
//   dataBridgingSavePgw : 'https://erp.transmedic.co.id/service/hris',

//   page: 1,
//   rows: 10,
//   headerToken: 'x-auth-token',
//   menuDinamic: true,
//   idleTimeOut: 1440, // menit
//   menuStatic: [{
//     "label": "Dashboard",
//     "icon": "fa fa-fw fa-chevron-right",
//     "routerLink": ["/"]
//   }],
//   /// TIGA INI JGN PERNAH DIUBAH OKE.. HATURNUHUN
//   resourceFile: /*'http://192.168.0.102:9797', //*/'http://192.168.5.68/authInfo',
//   resourceReport: /*'http://192.168.0.102:9797', //*/'http://192.168.5.68/authInfo',
//   socketIO: /*'http://192.168.0.143:2222'  //*/'http://bdg2.jasamedika.com:2304'
// }

// export default vlocal;

var vlocal = {
  apiBackend: '',
  dataMaster: /*'http://'+ window.location.hostname + ':8292', //*/'http://bdg2.jasamedika.com:2304/dataMaster',
  dataMasterNew: /*'http://'+ window.location.hostname + ':8292', //*/'http://bdg2.jasamedika.com:2304/dataMaster',

  report: /*'http://'+ window.location.hostname + ':8797', //*/'http://bdg2.jasamedika.com:2304/authInfo',
  authLogin: /*'http://'+ window.location.hostname + ':8797', //*/'http://bdg2.jasamedika.com:2304/authInfo',
  smartReport: /*'http://'+ window.location.hostname + ':8797', //*/ 'https://bdg2.jasamedika.com:2304/smartReport',
  dataHr1Mod1: /*'http://'+ window.location.hostname + ':8393', //*/ "http://bdg2.jasamedika.com:2304/dataHr1Mod1",
  dataHr1Mod2: /*'http://'+ window.location.hostname + ':8393', //*/'http://bdg2.jasamedika.com:2304/dataHr1Mod1',
  dataHr2Mod1: /*'http://'+ window.location.hostname + ':8393', //*/'http://bdg2.jasamedika.com:2304/dataHr1Mod1',
  dataHr2Mod2: /*'http://'+ window.location.hostname + ':8393', //*/'http://bdg2.jasamedika.com:2304/dataHr1Mod1',



  dataHr2Mod3: /*http://127.0.0.1:9292'*/ 'http://bdg2.jasamedika.com:2304/dataHr2Mod3',
  dataBSC: /*'http://192.168.0.143:9898', //*/'http://bdg2.jasamedika.com:2304/dataBSC',

  dataBridging: /*'http://192.168.0.143:9797', //*/ 'http://bdg2.jasamedika.com:2304/dataBridging',

  // dataBNI          : 'http://192.168.0.212:9933',

  dataBridgingRemun: 'http://bdg2.jasamedika.com:2304/service/remunerasi',

  dataBridgingSavePgw: 'https://erp.transmedic.co.id/service/hris',
  dataBridgingSmartPresence: 'http://192.168.5.68/rsf-smart',

  page: 1,
  rows: 10,
  headerToken: 'x-auth-token',
  menuDinamic: true,
  idleTimeOut: 1440, // menit
  menuStatic: [{
    "label": "Dashboard",
    "icon": "fa fa-fw fa-chevron-right",
    "routerLink": ["/"]
  }],
  /// TIGA INI JGN PERNAH DIUBAH OKE.. HATURNUHUN    
  resourceFile: /*'http://192.168.0.102:9797', //*/'http://bdg2.jasamedika.com:2304/authInfo',
  resourceReport: /*'http://192.168.0.102:9797', //*/'http://bdg2.jasamedika.com:2304/authInfo',
  socketIO: /*'http://192.168.0.143:2222'  //*/'http://bdg2.jasamedika.com:2304'
}


export default vlocal;