import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MenuItem } from 'primeng/primeng';
import { Router } from '@angular/router';

@Component({
   selector: 'app-menu-pkb-sponsorship',
   templateUrl: './menu-pkb-sponsorship.component.html',
   styleUrls: ['./menu-pkb-sponsorship.component.css'],
   encapsulation: ViewEncapsulation.None
})
export class MenuPkbSponsorshipComponent implements OnInit {
   items: MenuItem[]
   activeIndex: number = 0
   link = [
      'pengajuan-pelatihan-pkb-sponsorship',
      // 'tambah-pengajuan-pelatihan-pkb-sponsorship',
      // 'detail-pelatihan-pkb-sponsorship',
      'daftar-peserta-pelatihan-pkb-sponsorship',
      // 'tambah-peserta-pelatihan-pkb-sponsorship',
      'daftar-pengajuan-pelatihan-pkb-sponsorship',
      'daftar-terima-pengajuan-pelatihan-pkb-sponsorship'
   ]
   label: string[] = [
      'Pengajuan Pelatihan PKB Sponsorship',
      // 'Tambah Pengajuan Pelatihan PKB Sponsorship',
      // 'Detail Pelatihan PKB Sponsorship',
      'Daftar Peserta',
      // 'Tambah Peserta Pelatihan PKB Sponsorship',
      'Daftar Pengajuan Pelatihan PKB Sponsorship',
      'Daftar Terima Pengajuan Pelatihan PKB Sponsorhip'
   ]

   constructor(
      private route: Router
   ) {}

   ngOnInit(): void {
      console.log('label', this.label)
      let urlCurrent = this.route.url
      this.items = [] 

      for (let i = 0; i < this.link.length; i++) {
         if (urlCurrent == '/' + this.link[i]) {
            this.activeIndex = i
         }

         this.items.push({
            label: this.label[i],
            command: (e: any) => {
               this.itemCommand(i, this.link[i])
            }
         })
      }

      console.log('eifheifh')
      console.log(this.items)
   }

   itemCommand(index: number, routeString: string) {
      this.activeIndex = index
      this.route.navigate([routeString])
      localStorage.setItem('pkb-sponsorship-menu', String(this.activeIndex))
   }
}