import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService, InfoService } from '../../global';

@Component({
  selector: 'app-redirect-menu-evaluasi-jabatan',
  templateUrl: './redirect-menu-evaluasi-jabatan.component.html',
  styleUrls: ['./redirect-menu-evaluasi-jabatan.component.css']
})
export class RedirectMenuEvaluasiJabatanComponent implements OnInit {

  link  = [
    'daftar-list-pegawai-evaluasi-v2',
    'evaluasi-jabatan-pegawai-v2',
    'daftar-evaluasi-jabatan-pegawai-v2',
    'daftar-kirim-pengajuan-transisi-karir-v2',
    // 'daftar-pengajuan-transisi-karir-v2',
    'daftar-terima-pengajuan-transisi-karir-v2',
    // 'restrukturisasi-komponen-gaji-v2'
    'restrukturisasi-gaji-v2'
  ];

  constructor(
    private route: Router,
    private info: InfoService,
    private settingsService: SettingsService
  ) { }

  ngOnInit() {
    let urlList = this.settingsService.getCurrentURL();
    for (let i=0; i<this.link.length; i++){
        if (!(urlList['/' + this.link[i]] == undefined || urlList['/' + this.link[i]] == null || urlList['/' + this.link[i]] != 1)){
            localStorage.setItem('evaluasi-jabatan-menu', String(i));
            this.route.navigate([this.link[i]]);
            break;
        }
    }
  }

}
