export const routeKehadiran = [
    { path: 'jadwal-kerja-pegawai', loadChildren : () => import('./page-kehadiran.module').then(m => m.JadwalKerjaPegawaiModule) },
    { path: 'pengajuan-perubahan-jadwal-kerja', loadChildren : () => import('./page-kehadiran.module').then(m => m.PengajuanPerubahanJadwalKerjaModule) },
    { path: 'daftar-pengajuan-perubahan-jadwal-kerja', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarPengajuanPerubahanJadwalKerjaModule) },
    { path: 'daftar-pengajuan-perubahan-jadwal-kerja-v2', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarPengajuanPerubahanJadwalKerjaV2Module) },
    { path: 'monitoring-jadwal-kerja-pegawai', loadChildren : () => import('./page-kehadiran.module').then(m => m.MonitoringJadwalKerjaPegawaiModule) },
    { path: 'register-shift-pegawai', loadChildren : () => import('./page-kehadiran.module').then(m => m.RegisterShiftPegawaiModule) },
    { path: 'daftar-terima-pengajuan-perubahan-jadwal-kerja', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarTerimaPengajuanPerubahanJadwalKerjaModule) },
    { path: 'daftar-kirim-pengajuan-perubahan-jadwal-kerja', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarKirimPengajuanPerubahanJadwalKerjaModule) },
    { path: 'daftar-terima-pengajuan-perubahan-jadwal-kerja-v2', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarTerimaPengajuanPerubahanJadwalKerjaV2Module) },
    { path: 'daftar-kirim-pengajuan-perubahan-jadwal-kerja-v2', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarKirimPengajuanPerubahanJadwalKerjaV2Module) },

    { path: 'pengajuan-perjalanan-dinas', loadChildren : () => import('./page-kehadiran.module').then(m => m.PengajuanPerjalananDinasModule) },
    { path: 'daftar-pengajuan-perjalanan-dinas', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarPengajuanPerjalananDinasModule) },
    { path: 'daftar-pengajuan-perjalanan-dinas-v2', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarPengajuanPerjalananDinasV2Module) },
    { path: 'daftar-kirim-pengajuan-perjalanan-dinas', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarKirimPengajuanPerjalananDinasModule) },
    { path: 'daftar-terima-pengajuan-perjalanan-dinas', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarTerimaPengajuanPerjalananDinasModule) },
    { path: 'daftar-kirim-pengajuan-perjalanan-dinas-v2', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarKirimPengajuanPerjalananDinasV2Module) },
    { path: 'daftar-terima-pengajuan-perjalanan-dinas-v2', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarTerimaPengajuanPerjalananDinasV2Module) },

    { path: 'pengajuan-cuti-pegawai', loadChildren : () => import('./page-kehadiran.module').then(m => m.PengajuanCutiPegawaiModule) },    
    { path: 'daftar-pengajuan-cuti-pegawai', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarPengajuanCutiPegawaiModule) },
    { path: 'daftar-pengajuan-cuti-pegawai-v2', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarPengajuanCutiPegawaiV2Module) },
    { path: 'daftar-kirim-pengajuan-cuti-pegawai', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarKirimPengajuanCutiPegawaiModule) },
    { path: 'daftar-terima-pengajuan-cuti-pegawai', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarTerimaPengajuanCutiPegawaiModule) },

    { path: 'daftar-kirim-pengajuan-cuti-pegawai-v2', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarKirimPengajuanCutiPegawaiV2Module) },
    { path: 'daftar-terima-pengajuan-cuti-pegawai-v2', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarTerimaPengajuanCutiPegawaiV2Module) },

    { path: 'pengajuan-lembur-pegawai', loadChildren : () => import('./page-kehadiran.module').then(m => m.PengajuanLemburPegawaiModule) },
    { path: 'daftar-pengajuan-lembur-pegawai', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarPengajuanLemburPegawaiModule) },
    { path: 'daftar-pengajuan-lembur-pegawai-v2', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarPengajuanLemburPegawaiV2Module) },
    { path: 'daftar-kirim-pengajuan-lembur-pegawai', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarKirimPengajuanLemburPegawaiModule) },
    { path: 'daftar-terima-pengajuan-lembur-pegawai', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarTerimaPengajuanLemburPegawaiModule) },
    { path: 'daftar-kirim-pengajuan-lembur-pegawai-v2', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarKirimPengajuanLemburPegawaiV2Module) },
    { path: 'daftar-terima-pengajuan-lembur-pegawai-v2', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarTerimaPengajuanLemburPegawaiV2Module) },

    // { path: 'pengajuan-izin-dan-sakit', loadChildren : () => import('./page-kehadiran.module').then(m => m.PengajuanIzinDanSakitModule) },
    // { path: 'daftar-kirim-pengajuan-izin-dan-sakit', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarKirimPengajuanIzinDanSakitModule) },
    // { path: 'daftar-pengajuan-izin-dan-sakit', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarPengajuanIzinDanSakitModule) },
    // { path: 'daftar-terima-izin-dan-sakit', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarTerimaIzinDanSakitModule) },

	  { path: 'pembatalan-pengajuan-transaksi', loadChildren : () => import('./page-kehadiran.module').then(m => m.PengajuanPembatalanTransaksiModule) },
	  { path: 'daftar-kirim-pembatalan-pengajuan-transaksi', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarKirimPembatalanPengajuanTransaksiModule) },
	  { path: 'daftar-pembatalan-pengajuan-transaksi', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarPembatalanPengajuanTransaksiModule) },
    { path: 'daftar-terima-pembatalan-pengajuan-transaksi', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarTerimaPembatalanPengajuanTransaksiModule) },
    { path: 'daftar-kirim-pembatalan-pengajuan-transaksi-v2', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarKirimPembatalanPengajuanTransaksiV2Module) },
    { path: 'daftar-pembatalan-pengajuan-transaksi-v2', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarPembatalanPengajuanTransaksiV2Module) },
	  { path: 'daftar-terima-pembatalan-pengajuan-transaksi-v2', loadChildren : () => import('./page-kehadiran.module').then(m => m.DaftarTerimaPembatalanPengajuanTransaksiV2Module) },

	  { path: 'import-data-ketidakhadiran-pegawai', loadChildren : () => import('./page-kehadiran.module').then(m => m.UploadCutiPegawaiModule) },
	  { path: 'upload-saldo-cuti-pegawai', loadChildren : () => import('./page-kehadiran.module').then(m => m.UploadSaldoCutiPegawaiModule) },
    { path: 'laporan-ketidakhadiran', loadChildren : () => import('./page-kehadiran.module').then(m => m.LaporanKetidakhadiranModule) },

    { path: 'viewer-absensi', loadChildren : () => import('./page-kehadiran.module').then(m => m.ViewerAbsensiModule) },

    { path: 'alasan-keterlambatan', loadChildren : () => import('./page-kehadiran.module').then(m => m.AlasanKeterlambatanModule) },

    { path: 'jam-kerja-khusus', loadChildren : () => import('./page-kehadiran.module').then(m => m.JamKerjaKhususModule) },
    { path: 'koreksi-absensi', loadChildren : () => import('./page-kehadiran.module').then(m => m.KoreksiAbsensiModule) },
    { path: 'kerja-dari-rumah', loadChildren : () => import('./page-kehadiran.module').then(m => m.KerjaDariRumahModule) },
    { path: 'skrining-kesehatan', loadChildren : () => import('./page-kehadiran.module').then(m => m.SkriningKesehatanModule) },
];