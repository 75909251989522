export const routeDashboard = [
  {
    path: "",
    loadChildren: () =>
      import("./page-dashboard.module").then((m) => m.MainDashboardModule),
  },
  {
    path: "pencarian",
    loadChildren: () =>
      import("./page-dashboard.module").then((m) => m.PencarianModule),
  },
];
