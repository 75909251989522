import { Injectable } from '@angular/core';
import { Configuration }  from '../config';

import * as io from 'socket.io-client';

// class CallBackWrapper {

// 	private iCallBack : (ob : any, res : string) => any = null;

// 	constructor(callBack : (ob : any, res : string) => any = null, private ob : any){
// 		this.iCallBack = callBack;
// 	}	

// 	callBackOn(data){
// 		console.log('data  asli ' + data);
// 		this.iCallBack(this.ob, data);
// 	}
// }

@Injectable()
export class SocketService {

	private socket: any;
	//wrapper : CallBackWrapper[]; 

	private callBack : (data : string) => any = null

	constructor(){
		this.socket = io(Configuration.get().socketIO);
		console.log('socket connect');
	}

	

	on(stat, callBack : (data : string) => any = null){
		//this.wrapper[ob] = new CallBackWrapper(callBack, ob);
		this.callBack = callBack;
		this.socket.on(stat, function(data){
			//console.log('data  asli ' + data);
			callBack(data);
		});
	}

	emit(info, message){
		this.socket.emit(info, message);
	}
}	

