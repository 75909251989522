import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MenuItem } from 'primeng/primeng';
import { Router } from '@angular/router';

@Component({
  selector: 'app-resign-menu',
  templateUrl: './resign-menu.component.html',
  styleUrls: ['./resign-menu.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ResignMenuComponent implements OnInit {

  items: MenuItem[];
  activeIndex: number = 0;
  link  = [
    'daftar-kirim-pengajuan-resign-v2',
    'upload-download-formulir-resign-v2'
  ];

  constructor(private route: Router) { }

  ngOnInit() {
    let urlCurrent = this.route.url;
    for (let i = 0; i < this.link.length; i++) {
        if ( urlCurrent == '/' + this.link[i] ) {
            this.activeIndex = i;
            break;
        }
    }
    // this.activeIndex = parseInt(localStorage.getItem('resign-menu'));
    this.items = [
      {
          label: 'Pengajuan Resign',
          command: (event: any) => {
              this.activeIndex = 0;
              this.route.navigate(['daftar-kirim-pengajuan-resign-v2']);
              localStorage.setItem('resign-menu', String(this.activeIndex));
          }
      },
      {
          label: 'Unggah & Unduh Formulir Resign',
          command: (event: any) => {
              this.activeIndex = 1;
              this.route.navigate(['upload-download-formulir-resign-v2']);
              localStorage.setItem('resign-menu', String(this.activeIndex));
          }
      }

      ];
  }

}
