import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
declare var jsPDF: any;

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const PDF_EXTENSION = '.pdf';

@Injectable()
export class FileService {

  constructor() { }

  public exportAsExcelFileWithOpt(json: any[], options: any, headers: any[], titles: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, options);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

    XLSX.utils.sheet_add_aoa(worksheet, headers[1], {origin: headers[0]});
    
    let merge = [];

    for (let ii=0; ii<titles.length; ii++){
      worksheet[titles[ii][0]] = titles[ii][1];
      merge.push({
        s: { r: ii, c: 0 },
        e: { r: ii, c: headers[1].length - 1}
      });
    }

    worksheet["!merges"] = merge;
    
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  //PDF
  public exportAsPdfFile(title:string,header:any,json: any, fileName: string): void {
    var rows=[];
    var doc = new jsPDF('landscape');
    for(var key in json){
      var temp=json[key];
      var arr = Object.keys(temp).map(function(k) { return temp[k] });
      rows.push(arr);
    }
    
           doc.setFontSize(12);//doc.internal.pageSize.width/2
           doc.text(title, doc.internal.pageSize.width-95, 20,  'center');
             //doc.text('Col and row span', 14, 20);
            //doc.text("Overflow 'linebreak'", 7, doc.autoTable.previous.finalY  + 10);
            /* doc.autoTable(col, rows, {
                startY: doc.autoTable.previous.finalY + 15,
                margin: {horizontal: 7},
                bodyStyles: {valign: 'top'},
                styles: {overflow: 'linebreak', columnWidth: 'wrap'},
                columnStyles: {text: {columnWidth: 'auto'}}
              }); */
              var options ={
                // tableWidth: 'wrap',
                // columnWidth: 'auto',
                // pageBreak : 'always'
                styles: {
                  // overflow: 'linebreak',
                  columnWidth: 'wrap',
                  tableWidth: 'auto',
                },
                columnStyles: {
                  1: {columnWidth: 'auto'}
                } 
              };
              doc.autoTable(header, rows, options);
              doc.save(fileName+'_' + new Date().getTime() + PDF_EXTENSION); 
            }


//adi nambah buat cetak daftar-staus-pegawai

setCetakTampilDaftarStatusPegawai(judul: any, header: any, isiKolom: any, namaPdf: string){
  let isi=[];
  let doc = new jsPDF({
    orientation: 'landscape',
  });

  for(var key in isiKolom){
    var temp=isiKolom[key];
    var arr = Object.keys(temp).map(function(k) { return temp[k] });
    isi.push(arr);
  }

  let options = {
    // startY: false,
    // tableWidth: 'auto',
    // cellWidth: 'wrap',
    // showHead: 'everyPage',
    // tableLineColor: 200,
    // tableLineWidth: 0,
    columnStyles: {
      // 2: {
        0: {columnWidth: 9.2604166667},
        1: {columnWidth: 9.260416667},
        2: {columnWidth: 26.458333333},
        3: {columnWidth: 21.166666667},
        4: {columnWidth: 21.166666667},
        5: {columnWidth: 21.166666667},
        6: {columnWidth: 26.458333333},
        7: {columnWidth: 26.458333333},
        8: {columnWidth: 26.458333333},
        9: {columnWidth: 26.458333333},
      //     cellWidth: 'auto'
      // }
  },
  styles: {
    cellWidth: 'wrap',
    tableWidth: 'auto'
      // overflow: 'linebreak',
      // cellWidth: 'wrap',
      // font: 'arial',
      // fontSize: 12,
      // cellPadding: 1,
      // overflowColumns: 'linebreak'
  },
};

doc.text(judul, doc.internal.pageSize.width - 200, 10,  'center');
doc.autoTable(header, isi, options);
doc.save(namaPdf + '_' + new Date().getTime() + PDF_EXTENSION);


}


public exportAsExcelFileDaftarStatusPegawai(json: any[], excelFileName: string): void {
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
  const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
  XLSX.writeFile(workbook, 'Laporan Cetak Daftar Status Pegawai' + new Date().getTime() + '.xlsx');
  // this.saveAsExcelFile(excelBuffer, excelFileName);
}





}