import { Inject, Injectable, forwardRef, OnInit } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot,  } from '@angular/router';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { UserDto } from '../dto/iUserDto'; 
import { NotificationService } from './notification.service'; 
import { SettingsService } from './settings.service'; 
import { AlertService } from '../component/alert/alert.service'; 
import { PreviousRouteDetector } from '../service/previous.route.service'
 
@Injectable()
export class AuthGuard implements CanActivate, OnInit {

    private infoLoginObs = new Subject<boolean>();
    private tokenSubject = new Subject<UserDto>();
    private infoMonitorObs = new Subject<boolean>();
    private curLogin: boolean;
    private userDto: UserDto;

    constructor(private router: Router, 
        @Inject(forwardRef(() => PreviousRouteDetector)) private pRoute: PreviousRouteDetector,
        @Inject(forwardRef(() => AlertService)) private alert : AlertService, 
        @Inject(forwardRef(() => NotificationService)) private notificationService : NotificationService,
        @Inject(forwardRef(() => SettingsService)) private settingsService: SettingsService) { 

        this.notificationService.setAuthGuard(this);
        let mana = localStorage.getItem('user.data');
        if (mana === undefined || mana === null){
            mana = localStorage.getItem('user.data.viewer');
            if (mana !== undefined && mana !== null && (window.location.hash.indexOf("viewer-") > -1)){
                this.userDto = JSON.parse(mana);
                this.startMonitoringAbsensi();
            }
        }else{
            this.userDto = JSON.parse(mana);
        }
        
    }

    ngOnInit(){
        this.notificationService.setAuthGuard(this);
    }

    clearStorage(){
        this.del_message_to_sw();
        sessionStorage.clear();
        localStorage.clear();
    }

    del_message_to_sw(){
        if ('serviceWorker' in navigator && navigator.serviceWorker.controller !== undefined && navigator.serviceWorker.controller !== null) {
            navigator.serviceWorker.controller.postMessage(JSON.stringify({kdProfile: -1000}));
        }
    }

    startMonitoringAbsensi(){
        this.infoMonitorObs.next(true)
        this.clearStorage();
        //localStorage.clear();
        if (this.userDto !== undefined && this.userDto !== null){
            localStorage.setItem('user.data.viewer', JSON.stringify(this.userDto));
        }
    }

    stopMonitoringAbsensi(){
        this.infoMonitorObs.next(false)
    }

    getMonitorAbsensi(): Observable<boolean> {
        return this.infoMonitorObs.asObservable();
    }
 
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {     
        this.isLogin();
        if (this.curLogin) {
            let urlList = this.settingsService.getCurrentURL();
            let curUrl = state.url

            
            if (curUrl == '/viewer-absensi'){
                this.startMonitoringAbsensi();
            } else {    
                this.stopMonitoringAbsensi();
                if (this.pRoute.getPreviousUrl() == '/viewer-absensi'){
                    this.clearStorage();
                    //localStorage.clear();
                    this.setUserDto(null);
                    this.isLogin();
                    this.router.navigate(['login'])
                    return false
                }
            }
            
            if (curUrl == '/' || curUrl == '/infopegawaiview' || curUrl.startsWith('/pencarian') ){
                return true;
            }
            
            if (this.getUserDto().kdKelompokUser == 1){               
                return true;
            }

            if (this.getUserDto().kdKelompokUser <= 0){
                this.alert.warn('Peringatan', 'Maaf, anda tidak punya hak akses')
                return false;
            } else if (curUrl == '/viewer-absensi') {
                return true;
            } else if (urlList[curUrl] == undefined || urlList[curUrl] == null || urlList[curUrl] != 1){               
                this.alert.warn('Peringatan', 'Maaf, anda tidak punya hak akses, silahkan hubungi admin')
                return false;
                //  return true;
            }


            return true;  
        } else {
            this.router.navigate(['login']/*, { queryParams: { returnUrl: state.url }}*/);
            return false;
        }
     }

     setUserDto(userDto: UserDto){
         this.userDto = userDto;
         if (this.userDto !== undefined || this.userDto !== null ){
             localStorage.setItem('user.data', JSON.stringify(this.userDto));
         }
     }

     getUserDto(){
        if ( this.userDto.kdLokasi == undefined ||  this.userDto.kdLokasi == null) {
            this.userDto.kdLokasi = '001'
        }   
        return this.userDto;
     }

    checkLogin() {
        if (this.userDto != undefined && this.userDto != null){
            this.curLogin = true;

            this.notificationService.notifListperRuangan(this.userDto.ruangan);
            this.notificationService.notifListperJabatan(this.userDto.pegawai);

        } else {
            this.curLogin = false;            
        }
        this.tokenSubject.next(this.userDto);
        return this.curLogin;
    }

    isLogin(){
        this.checkLogin();
        this.infoLoginObs.next(this.curLogin);
        return this.curLogin;
    }

    getInfoToken (): Observable<UserDto> {
        return this.tokenSubject.asObservable();
    }

    getInfoLogin(): Observable<boolean> {
        return this.infoLoginObs.asObservable();
    }

    getNamaLogin() : string {
        if (this.userDto.pegawai === undefined || this.userDto.pegawai === null){
           return "";
        } else {    
            return this.userDto.pegawai.namaLengkap;
        }
    }

    getNamaLokasi() : string {
        if (this.userDto.lokasi === undefined || this.userDto.lokasi === null){
           return "";
        } else {
            return this.userDto.lokasi.namaRuangan;
        }
    }

    getNamaPerusahaan() : string {
        if (this.userDto.profile === undefined || this.userDto.profile === null){
           return "";
        } else {
            return this.userDto.profile.namaLengkap;
        }
    }

    getNamaDepartemen(): string {
        if (this.userDto.ruangan === undefined || this.userDto.ruangan === null) {
            return '';
         } else {
             return this.userDto.ruangan.namaRuangan;
         }
    }

    getWaktuLogin(): string {
        if (this.userDto.waktuLogin === undefined || this.userDto.waktuLogin === null) {
            return '';
         } else {
             return this.userDto.waktuLogin;
         }
    }


    logout() {
        this.clearStorage();
        //localStorage.clear();
    }

    getImageThumbs() : string {
        if (this.userDto.pegawai === undefined || 
            this.userDto.pegawai === null || 
            this.userDto.pegawai.photoDiri === undefined|| 
            this.userDto.pegawai.photoDiri === null){
            return 'profile1213123.png';
        } else {
            return this.userDto.pegawai.photoDiri;
        }
    }
}