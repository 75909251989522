
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[NoExponen]'
})
export class NoExponen {

  constructor(private el: ElementRef) { }

  @Input() NoExponen: boolean;

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    let e = <KeyboardEvent> event;
    if (this.NoExponen) {
        if (e.which === 69 || e.which === 101) {
            e.preventDefault();
        }
    }
  }

}
