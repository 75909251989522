export const routeMasterData = [
    // Global Menu  
    { path: 'master-printer', loadChildren: () => import('./master-aset.module').then(m => m.PrinterModule) },
    { path: 'alat', loadChildren: () => import('./master-aset.module').then(m => m.AlatModule) },
    { path: 'master-dokumen', loadChildren: () => import('./master-dokumen.module').then(m => m.DokumenModule) },
    { path: 'jenis-pajak', loadChildren: () => import('./master-pajak.module').then(m => m.JenisPajakModule) },

    //Menu Profile Perusahaan
    { path: 'master-kelompok-pemilik-profile-profile', loadChildren: () => import('./master-profile.module').then(m => m.KelompokPemilikProfileModule) },
    { path: 'master-pemilik-profile-profile', loadChildren: () => import('./master-profile.module').then(m => m.PemilikProfileModule) },
    { path: 'master-jenis-profile-profile', loadChildren: () => import('./master-profile.module').then(m => m.JenisProfileModule) },
    { path: 'master-ruangan-profile', loadChildren: () => import('./master-profile.module').then(m => m.RuanganModule) },
    { path: 'master-profile-profile', loadChildren: () => import('./master-profile.module').then(m => m.ProfileModule) },
    { path: 'master-lokasi-kerja', loadChildren: () => import('./master-profile.module').then(m => m.LokasiKerjaModule) },
    // { path: 'master-kategori-pegawai-pegawai', loadChildren: () => import('./master-pegawai.module').then(m => m.KategoriPegawaiModule) },

    //Menu Struktur Organisasi
    { path: 'master-departemen-struktur', loadChildren: () => import('./master-profile.module').then(m => m.DepartemenModule) },

    //Menu Wilayah
    { path: 'master-jenis-alamat-wilayah', loadChildren: () => import('./master-wilayah.module').then(m => m.JenisAlamatModule) },

    //Menu Tenaga
    { path: 'master-jenis-tenaga', loadChildren: () => import('./master-pegawai.module').then(m => m.JenisTenagaModule) },
    //Grade Jabatan
    { path: 'master-grade-jabatan', loadChildren: () => import('./master-pegawai.module').then(m => m.GradeJabatanModule) },
    // Pelatihan
    { path: 'master-metode-pelatihan', loadChildren: () => import('./master-pegawai.module').then(m => m.MetodePelatihanModule) },
    { path: 'master-skala-diklat', loadChildren: () => import('./master-pegawai.module').then(m => m.SkalaDiklatModule) },
    { path: 'master-kuota-diklat', loadChildren: () => import('./master-pegawai.module').then(m => m.KuotaDiklatModule) },

    { path: 'master-jabatan-fungsional', loadChildren: () => import('./master-pegawai.module').then(m => m.JabatanStrukturalModule) },
    { path: 'master-kategori-ruangan', loadChildren: () => import('./master-pegawai.module').then(m => m.KategoriRuanganModule) },
    { path: 'master-kategori-hukuman-disiplin', loadChildren: () => import('./master-pegawai.module').then(m => m.KategoriHukumanDisiplinModule) },
    { path: 'master-kategori-pegawai', loadChildren: () => import('./master-pegawai.module').then(m => m.KelompokJabatanModule) },
    { path: 'master-divisi', loadChildren: () => import('./master-pegawai.module').then(m => m.MasterDivisiModule) },
    { path: 'master-direktorat', loadChildren: () => import('./master-pegawai.module').then(m => m.DirektoratModule) },
    { path: 'master-sub-unit', loadChildren: () => import('./master-pegawai.module').then(m => m.SubUnitModule) },
    { path: 'master-level-kompetensi', loadChildren: () => import('./master-pegawai.module').then(m => m.LevelKompetensitModule) },
    { path: 'master-map-kategory-ruangan-to-ruangan', loadChildren: () => import('./master-pegawai.module').then(m => m.KategoriRuanganMapToMapModule) },
    { path: 'master-komponen-penilaian-kinerja', loadChildren: () => import('./master-pegawai.module').then(m => m.KomponenPenilaianKinerjaModule) },
    { path: 'master-komponen-tindakan-keperawatan', loadChildren: () => import('./master-pegawai.module').then(m => m.KomponenTindakanKeperawatanModule) },
    { path: 'master-institusi-pendidikan', loadChildren: () => import('./master-pegawai.module').then(m => m.InstitusiPendidikanModule) },
    { path: 'master-jurusan', loadChildren: () => import('./master-pegawai.module').then(m => m.JurusanModule) },
    { path: 'master-map-kategori-pegawai-to-jenis-pegawai', loadChildren: () => import('./master-pegawai.module').then(m => m.MapKelompokJabatanToJenisPegawaiModule) },
    { path: 'master-map-jenis-tenaga-to-kelompok-jabatan', loadChildren: () => import('./master-pegawai.module').then(m => m.MapJenisTenagaToKelompokJabatanModule) },
    { path: 'master-kategori-pegawai-pegawai', loadChildren: () => import('./master-pegawai.module').then(m => m.KategoriPegawaiModule) },

    //Menu Pegawai
    { path: 'master-title-pegawai-pegawai', loadChildren: () => import('./master-pegawai.module').then(m => m.TitlePegawaiModule) },
    { path: 'master-jenis-pegawai-pegawai', loadChildren: () => import('./master-pegawai.module').then(m => m.JenisPegawaiModule) },
    { path: 'master-jabatan-pegawai', loadChildren: () => import('./master-pegawai.module').then(m => m.JabatanModule) },
    { path: 'master-pangkat-pegawai', loadChildren: () => import('./master-pegawai.module').then(m => m.PangkatModule) },
    { path: 'master-profile-remun', loadChildren: () => import('./master-pegawai.module').then(m => m.ProfileRemunModule) },
    { path: 'master-Iku', loadChildren: () => import('./master-pegawai.module').then(m => m.IkuModule) },
    { path: 'master-golonganPegawai-pegawai', loadChildren: () => import('./master-pegawai.module').then(m => m.GolonganPegawaiFormModule) },
    { path: 'jenis-petugas-pelaksana-pegawai', loadChildren: () => import('./master-evaluasi-pendidikan.module').then(m => m.JenisPetugasPelaksanaModule) },
    { path: 'master-type-pegawai-pegawai', loadChildren: () => import('./master-pegawai.module').then(m => m.TypePegawaiModule) },
    { path: 'master-status-pegawai', loadChildren: () => import('./master-informasi.module').then(m => m.StatusModule) },
    { path: 'master-status-perkawinan-pegawai', loadChildren: () => import('./master-informasi.module').then(m => m.StatusModule) },
    { path: 'master-pendidikan-pegawai', loadChildren: () => import('./master-evaluasi-pendidikan.module').then(m => m.PendidikanModule) },
    { path: 'fakultas-pegawai', loadChildren: () => import('./master-evaluasi-pendidikan.module').then(m => m.FakultasModule) },
    { path: 'master-kualifikasi-jurusan-pegawai', loadChildren: () => import('./master-evaluasi-pendidikan.module').then(m => m.KualifikasiJurusanModule) },
    { path: 'master-pekerjaan-pegawai', loadChildren: () => import('./master-pegawai.module').then(m => m.PekerjaanModule) },
    { path: 'master-hubungan-keluarga-pegawai', loadChildren: () => import('./master-biodata.module').then(m => m.HubunganKeluargaModule) },
    { path: 'hubungan-pegawai', loadChildren: () => import('./master-pegawai.module').then(m => m.HubunganModule) },
    { path: 'master-level-tingkat-pegawai', loadChildren: () => import('./master-khusus.module').then(m => m.LevelTingkatModule) },
    { path: 'jenis-organisasi-pegawai', loadChildren: () => import('./master-informasi.module').then(m => m.JenisOrganisasiModule) },

    { path: 'range-masakerja', loadChildren: () => import('./master-khusus.module').then(m => m.RangeMasaKerjaModule) },
    { path: 'range-masakerja-bpjs', loadChildren: () => import('./master-khusus.module').then(m => m.RangeMasaKerjaBPJSModule) },

    //Menu Penghargaan/Pelanggaran
    { path: 'master-asal-award-offence', loadChildren: () => import('./master-informasi.module').then(m => m.AsalModule) },
    { path: 'keterangan-alasan-award-offence', loadChildren: () => import('./master-informasi.module').then(m => m.KeteranganAlasanModule) },
    { path: 'kelompok-transaksi-detail-award-offence', loadChildren: () => import('./master-kelompok-transaksi.module').then(m => m.KelompokTransaksiDetailModule) },
    { path: 'master-award-offence', loadChildren: () => import('./master-khusus.module').then(m => m.AwardOffenceModule) },
    { path: 'cara-bayar-award-offence', loadChildren: () => import('./master-khusus.module').then(m => m.CaraBayarModule) },
    { path: 'master-jenis-dokumen-award-offence', loadChildren: () => import('./master-dokumen.module').then(m => m.JenisDokumenModule) },


    //Menu Fasilitas Pegawai (Perlengkapan Kerja)
    { path: 'master-jenis-aset-fasilitas-pegawai', loadChildren: () => import('./master-aset.module').then(m => m.JenisAsetModule) },
    { path: 'kategori-aset-fasilitas-pegawai', loadChildren: () => import('./master-aset.module').then(m => m.KategoriAsetModule) },
    { path: 'kelompok-aset-fasilitas-pegawai', loadChildren: () => import('./master-aset.module').then(m => m.kelompokasetModule) },
    { path: 'master-warna-produk-fasilitas-pegawai', loadChildren: () => import('./master-produk.module').then(m => m.WarnaProdukModule) },
    { path: 'bahan-produk-fasilitas-pegawai', loadChildren: () => import('./master-produk.module').then(m => m.BahanProdukModule) },
    { path: 'kondisi-produk-fasilitas-pegawai', loadChildren: () => import('./master-produk.module').then(m => m.KondisiProdukModule) },
    { path: 'master-merk-produk', loadChildren: () => import('./master-produk.module').then(m => m.MerkProdukModule) },
    { path: 'master-type-produk', loadChildren: () => import('./master-produk.module').then(m => m.TypeProdukModule) },
    { path: 'master-produsen-produk', loadChildren: () => import('./master-produk.module').then(m => m.ProdusenProdukModule) },
    { path: 'metode-delivery-fasilitas-pegawai', loadChildren: () => import('./master-aset.module').then(m => m.MetodeDeliveryModule) },
    { path: 'satuan-standar-fasilitas-pegawai', loadChildren: () => import('./master-aset.module').then(m => m.SatuanStandarModule) },
    { path: 'transmisi-fasilitas-pegawai', loadChildren: () => import('./master-aset.module').then(m => m.TransmisiModule) },
    { path: 'registrasiaset-fasilitas-pegawai', loadChildren: () => import('./master-aset.module').then(m => m.RegistrasiAsetModule) },
    { path: 'master-aset-fasilitas', loadChildren: () => import('./master-aset.module').then(m => m.MasterAsetFasilitasModule) },
    { path: 'pegawai-sk-fasilitas-new', loadChildren: () => import('./master-aset.module').then(m => m.PegawaiSkFasilitasNewModule) },

    //Menu Pemeriksaan Pegawai
    { path: 'master-satuan-hasil-pemeriksaan-pegawai', loadChildren: () => import('./master-informasi.module').then(m => m.SatuanHasilModule) },
    { path: 'komponen-pemeriksaan-pegawai', loadChildren: () => import('./master-informasi.module').then(m => m.KomponenModule) },
    { path: 'komponen-hasil-pemeriksaan-pegawai', loadChildren: () => import('./master-informasi.module').then(m => m.KomponenHasilModule) },


    { path: 'komponen-pemeriksaanobjektif', loadChildren: () => import('./master-informasi.module').then(m => m.KomponenPemeriksaanObjektifModule) },
    { path: 'komponen-pemeriksaanlaboratorium', loadChildren: () => import('./master-informasi.module').then(m => m.KomponenPemeriksaanLaboratoriumModule) },
    { path: 'komponen-penilaiankompetensi', loadChildren: () => import('./master-informasi.module').then(m => m.KomponenPenilaianKompetensiModule) },
    { path: 'komponen-gaji', loadChildren: () => import('./master-informasi.module').then(m => m.KomponenGajiModule) },
    { path: 'komponen-evaluasiorientasi', loadChildren: () => import('./master-informasi.module').then(m => m.KomponenEvaluasiOrientasiModule) },
    { path: 'komponen-evaluasijabatan', loadChildren: () => import('./master-informasi.module').then(m => m.KomponenEvaluasiJabatanModule) },

    //Menu Orientasi Pegawai
    { path: 'master-asal-anggaran-orientasi', loadChildren: () => import('./master-informasi.module').then(m => m.AsalModule) },
    { path: 'master-asal-peserta-orientasi', loadChildren: () => import('./master-informasi.module').then(m => m.AsalModule) },
    { path: 'keterangan-alasan-orientasi', loadChildren: () => import('./master-informasi.module').then(m => m.KeteranganAlasanModule) },
    { path: 'master-kelompok-transaksi-orientasi', loadChildren: () => import('./master-kelompok-transaksi.module').then(m => m.KelompokTransaksiModule) },
    { path: 'kelompok-transaksi-detail-orientasi', loadChildren: () => import('./master-kelompok-transaksi.module').then(m => m.KelompokTransaksiDetailModule) },
    { path: 'jenis-petugas-pelaksana-orientasi', loadChildren: () => import('./master-evaluasi-pendidikan.module').then(m => m.JenisPetugasPelaksanaModule) },
    { path: 'kelompok-evaluasi-orientasi', loadChildren: () => import('./master-evaluasi-pendidikan.module').then(m => m.KelompokEvaluasiModule) },
    { path: 'master-event-orientasi', loadChildren: () => import('./master-kalender.module').then(m => m.EventModule) },
    { path: 'produk-nama-orientasi', loadChildren: () => import('./master-produk.module').then(m => m.ProdukModule) },
    { path: 'produk-biaya-orientasi', loadChildren: () => import('./master-produk.module').then(m => m.ProdukModule) },
    { path: 'master-jenis-tarif-orientasi', loadChildren: () => import('./master-khusus.module').then(m => m.JenisTarifModule) },
    { path: 'komponen-orientasi', loadChildren: () => import('./master-informasi.module').then(m => m.KomponenModule) },
    { path: 'harga-netto-produk-by-kelas-orientasi', loadChildren: () => import('./master-produk.module').then(m => m.HargaNettoProdukBykelasModule) },


    //Menu Perjalanan Dinas
    { path: 'produk-perjalanan-dinas', loadChildren: () => import('./master-produk.module').then(m => m.ProdukModule) },
    { path: 'komponen-perjalanan-dinas', loadChildren: () => import('./master-informasi.module').then(m => m.KomponenModule) },
    { path: 'master-kelas-perjalanan-dinas', loadChildren: () => import('./master-khusus.module').then(m => m.KelasModule) },
    { path: 'master-jenis-tarif-perjalanan-dinas', loadChildren: () => import('./master-khusus.module').then(m => m.JenisTarifModule) },
    { path: 'harga-netto-produk-by-kelas-perjalanan-dinas', loadChildren: () => import('./master-produk.module').then(m => m.HargaNettoProdukBykelasModule) },
    { path: 'keterangan-alasan-perjalanan-dinas', loadChildren: () => import('./master-informasi.module').then(m => m.KeteranganAlasanModule) },
    { path: 'master-kelompok-transaksi-perjalanan-dinas', loadChildren: () => import('./master-kelompok-transaksi.module').then(m => m.KelompokTransaksiModule) },
    { path: 'kelompok-transaksi-detail-perjalanan-dinas', loadChildren: () => import('./master-kelompok-transaksi.module').then(m => m.KelompokTransaksiDetailModule) },
    { path: 'master-jenis-dokumen-perjalanan-dinas', loadChildren: () => import('./master-dokumen.module').then(m => m.JenisDokumenModule) },

    //Menu Jadwal
    { path: 'master-harilibur-jadwal', loadChildren: () => import('./master-kalender.module').then(m => m.HariLiburModule) },
    { path: 'kelompok-shift-kerja-jadwal', loadChildren: () => import('./master-jadwal.module').then(m => m.KelompokShiftKerjaModule) },
    { path: 'shift-kerja-jadwal', loadChildren: () => import('./master-jadwal.module').then(m => m.ShiftKerjaModule) },
    { path: 'shift-kerja-formulasi-jadwal', loadChildren: () => import('./master-jadwal.module').then(m => m.ShiftKerjaFormulasiModule) },

    //Menu Cuti Pegawai
    { path: 'keterangan-alasan-cuti', loadChildren: () => import('./master-informasi.module').then(m => m.KeteranganAlasanModule) },
    { path: 'master-kelompok-transaksi-cuti', loadChildren: () => import('./master-kelompok-transaksi.module').then(m => m.KelompokTransaksiModule) },
    { path: 'kelompok-transaksi-detail-cuti', loadChildren: () => import('./master-kelompok-transaksi.module').then(m => m.KelompokTransaksiDetailModule) },
    { path: 'master-status-cuti', loadChildren: () => import('./master-informasi.module').then(m => m.StatusModule) },
    { path: 'range-cuti', loadChildren: () => import('./master-khusus.module').then(m => m.RangeModule) },
    { path: 'master-jenis-dokumen-cuti', loadChildren: () => import('./master-dokumen.module').then(m => m.JenisDokumenModule) },


    { path: 'range-masakerja-cuti', loadChildren: () => import('./master-khusus.module').then(m => m.RangeMasaKerjaCutiModule) },
    { path: 'range-keterlambatan', loadChildren: () => import('./master-khusus.module').then(m => m.RangeKeterlambatanModule) },
    { path: 'range-liburharioff-liburnasional', loadChildren: () => import('./master-khusus.module').then(m => m.RangeLiburHariOffLiburNasionalModule) },
    { path: 'range-liburharikerjanormal', loadChildren: () => import('./master-khusus.module').then(m => m.RangeLiburHariKerjaNormalModule) },

    //Menu Lembur
    { path: 'keterangan-alasan-lembur', loadChildren: () => import('./master-informasi.module').then(m => m.KeteranganAlasanModule) },
    { path: 'master-kelompok-transaksi-lembur', loadChildren: () => import('./master-kelompok-transaksi.module').then(m => m.KelompokTransaksiModule) },
    { path: 'kelompok-transaksi-detail-lembur', loadChildren: () => import('./master-kelompok-transaksi.module').then(m => m.KelompokTransaksiDetailModule) },
    { path: 'range-lembur', loadChildren: () => import('./master-khusus.module').then(m => m.RangeModule) },
    { path: 'master-status-lembur', loadChildren: () => import('./master-informasi.module').then(m => m.StatusModule) },
    { path: 'master-jenis-dokumen-lembur', loadChildren: () => import('./master-dokumen.module').then(m => m.JenisDokumenModule) },

    //Menu Resign
    { path: 'keterangan-alasan-resign', loadChildren: () => import('./master-informasi.module').then(m => m.KeteranganAlasanModule) },
    { path: 'master-kelompok-transaksi-resign', loadChildren: () => import('./master-kelompok-transaksi.module').then(m => m.KelompokTransaksiModule) },
    { path: 'kelompok-transaksi-detail-resign', loadChildren: () => import('./master-kelompok-transaksi.module').then(m => m.KelompokTransaksiDetailModule) },
    { path: 'master-status-resign', loadChildren: () => import('./master-informasi.module').then(m => m.StatusModule) },
    { path: 'master-jenis-dokumen-resign', loadChildren: () => import('./master-dokumen.module').then(m => m.JenisDokumenModule) },

    //Menu Pinjaman
    { path: 'keterangan-alasan-pinjaman', loadChildren: () => import('./master-informasi.module').then(m => m.KeteranganAlasanModule) },
    { path: 'master-kelompok-transaksi-pinjaman', loadChildren: () => import('./master-kelompok-transaksi.module').then(m => m.KelompokTransaksiModule) },
    { path: 'kelompok-transaksi-detail-pinjaman', loadChildren: () => import('./master-kelompok-transaksi.module').then(m => m.KelompokTransaksiDetailModule) },
    { path: 'jenis-bunga-pinjaman', loadChildren: () => import('./master-pinjaman.module').then(m => m.JenisBungaModule) },
    { path: 'jenis-suku-bunga-pinjaman', loadChildren: () => import('./master-pinjaman.module').then(m => m.JenisSukuBungaModule) },
    { path: 'metode-perhitungan-pinjaman', loadChildren: () => import('./master-pinjaman.module').then(m => m.MetodePerhitunganModule) },
    { path: 'metode-pembayaran-pinjaman', loadChildren: () => import('./master-pinjaman.module').then(m => m.MetodePembayaranModule) },
    { path: 'cara-bayar-pinjaman', loadChildren: () => import('./master-khusus.module').then(m => m.CaraBayarModule) },
    { path: 'produk-pinjaman', loadChildren: () => import('./master-produk.module').then(m => m.ProdukModule) },
    { path: 'master-jenis-dokumen-pinjaman', loadChildren: () => import('./master-dokumen.module').then(m => m.JenisDokumenModule) },

    { path: 'range-waktupinjaman', loadChildren: () => import('./master-khusus.module').then(m => m.RangeWaktuPinjamanModule) },
    { path: 'range-pinjaman', loadChildren: () => import('./master-khusus.module').then(m => m.RangePinjamanModule) },
    { path: 'range-masakerja-pinjaman', loadChildren: () => import('./master-khusus.module').then(m => m.RangeMasaKerjaPinjamanModule) },

    //Menu Reimburs
    { path: 'keterangan-alasan-reimburs', loadChildren: () => import('./master-informasi.module').then(m => m.KeteranganAlasanModule) },
    { path: 'master-kelompok-transaksi-reimburs', loadChildren: () => import('./master-kelompok-transaksi.module').then(m => m.KelompokTransaksiModule) },
    { path: 'kelompok-transaksi-detail-reimburs', loadChildren: () => import('./master-kelompok-transaksi.module').then(m => m.KelompokTransaksiDetailModule) },
    { path: 'jenis-transaksi-reimburs', loadChildren: () => import('./master-aset.module').then(m => m.JenisTransaksiModule) },
    { path: 'paket-reimburs', loadChildren: () => import('./master-aset.module').then(m => m.PaketModule) },
    { path: 'produk-reimburs', loadChildren: () => import('./master-produk.module').then(m => m.ProdukModule) },
    { path: 'master-jenis-dokumen-reimburs', loadChildren: () => import('./master-dokumen.module').then(m => m.JenisDokumenModule) },


    { path: 'range-masakerja-reimburs', loadChildren: () => import('./master-khusus.module').then(m => m.RangeMasaKerjaReimbursModule) },

    //Menu Reimburs Perjalanan Dinas
    { path: 'keterangan-alasan-reimburs-perjalanan-dinas', loadChildren: () => import('./master-informasi.module').then(m => m.KeteranganAlasanModule) },
    { path: 'master-kelompok-transaksi-reimburs-perjalanan-dinas', loadChildren: () => import('./master-kelompok-transaksi.module').then(m => m.KelompokTransaksiModule) },
    { path: 'kelompok-transaksi-detail-reimburs-perjalanan-dinas', loadChildren: () => import('./master-kelompok-transaksi.module').then(m => m.KelompokTransaksiDetailModule) },
    { path: 'jenis-transaksi-reimburs-perjalanan-dinas', loadChildren: () => import('./master-aset.module').then(m => m.JenisTransaksiModule) },
    { path: 'paket-reimburs-perjalanan-dinas', loadChildren: () => import('./master-aset.module').then(m => m.PaketModule) },
    { path: 'produk-reimburs-perjalanan-dinas', loadChildren: () => import('./master-produk.module').then(m => m.ProdukModule) },
    { path: 'master-kelas-reimburs-perjalanan-dinas', loadChildren: () => import('./master-khusus.module').then(m => m.KelasModule) },
    { path: 'harga-netto-produk-by-kelas-reimburs-perjalanan-dinas', loadChildren: () => import('./master-produk.module').then(m => m.HargaNettoProdukBykelasModule) },
    { path: 'master-jenis-dokumen-reimburs-perjalanan-dinas', loadChildren: () => import('./master-dokumen.module').then(m => m.JenisDokumenModule) },
    //Menu Evaluasi Jabatan
    { path: 'master-jenis-waktu-evaluasi', loadChildren: () => import('./master-kalender.module').then(m => m.JenisWaktuModule) },
    { path: 'keterangan-alasan-evaluasi', loadChildren: () => import('./master-informasi.module').then(m => m.KeteranganAlasanModule) },
    { path: 'master-kelompok-transaksi-evaluasi', loadChildren: () => import('./master-kelompok-transaksi.module').then(m => m.KelompokTransaksiModule) },
    { path: 'kelompok-transaksi-detail-evaluasi', loadChildren: () => import('./master-kelompok-transaksi.module').then(m => m.KelompokTransaksiDetailModule) },
    { path: 'type-data-object-evaluasi', loadChildren: () => import('./master-aplikasi.module').then(m => m.TypeDataObjectModule) },
    { path: 'kelompok-evaluasi-evaluasi', loadChildren: () => import('./master-evaluasi-pendidikan.module').then(m => m.KelompokEvaluasiModule) },
    { path: 'komponen-evaluasi', loadChildren: () => import('./master-informasi.module').then(m => m.KomponenModule) },
    { path: 'master-jenis-dokumen-evaluasi', loadChildren: () => import('./master-dokumen.module').then(m => m.JenisDokumenModule) },

    //Menu PHK
    { path: 'keterangan-alasan-phk', loadChildren: () => import('./master-informasi.module').then(m => m.KeteranganAlasanModule) },
    { path: 'master-kelompok-transaksi-phk', loadChildren: () => import('./master-kelompok-transaksi.module').then(m => m.KelompokTransaksiModule) },
    { path: 'kelompok-transaksi-detail-phk', loadChildren: () => import('./master-kelompok-transaksi.module').then(m => m.KelompokTransaksiDetailModule) },
    { path: 'master-jenis-dokumen-phk', loadChildren: () => import('./master-dokumen.module').then(m => m.JenisDokumenModule) },
    { path: 'status-phk', loadChildren: () => import('./master-informasi.module').then(m => m.StatusModule) },
    { path: 'profile-struktur-gaji-by-mkgp-phk', loadChildren: () => import('./master-profile.module').then(m => m.ProfileStrukturGajiByMkgpModule) },


    { path: 'range-masakerja-phk', loadChildren: () => import('./master-khusus.module').then(m => m.RangeMasaKerjaPhkModule) },

    //Menu Asuransi
    { path: 'master-rekanan-asuransi', loadChildren: () => import('./master-asuransi.module').then(m => m.RekananModule) },
    { path: 'master-hubungan-peserta-asuransi-asuransi', loadChildren: () => import('./master-asuransi.module').then(m => m.HubunganPesertaAsuransiModule) },
    { path: 'golongan-asuransi', loadChildren: () => import('./master-asuransi.module').then(m => m.GolonganAsuransiModule) },

    //Menu Pajak
    { path: 'master-penghasilan-tidak-kena-pajak-pajak', loadChildren: () => import('./master-pajak.module').then(m => m.PenghasilanTidakKenaPajakModule) },
    { path: 'produk-pajak', loadChildren: () => import('./master-produk.module').then(m => m.ProdukModule) },
    { path: 'komponen-pajak', loadChildren: () => import('./master-informasi.module').then(m => m.KomponenModule) },
    { path: 'pajak-pajak', loadChildren: () => import('./master-pajak.module').then(m => m.PajakModule) },
    { path: 'objek-pajak-pajak', loadChildren: () => import('./master-pajak.module').then(m => m.ObjekPajakModule) },

    //Menu Penggajian
    { path: 'periode-penggajian', loadChildren: () => import('./master-kalender.module').then(m => m.PeriodeModule) },
    { path: 'range-penggajian', loadChildren: () => import('./master-khusus.module').then(m => m.RangeModule) },
    { path: 'produk-penggajian', loadChildren: () => import('./master-produk.module').then(m => m.ProdukModule) },
    { path: 'produk-fasilitas', loadChildren: () => import('./master-produk.module').then(m => m.ProdukModule) },
    { path: 'komponen-penggajian', loadChildren: () => import('./master-informasi.module').then(m => m.KomponenModule) },
    { path: 'profile-struktur-gaji-by-mkgp-penggajian', loadChildren: () => import('./master-profile.module').then(m => m.ProfileStrukturGajiByMkgpModule) },

    //Menu THR
    { path: 'periode-thr', loadChildren: () => import('./master-kalender.module').then(m => m.PeriodeModule) },
    { path: 'range-thr', loadChildren: () => import('./master-khusus.module').then(m => m.RangeModule) },
    { path: 'produk-thr', loadChildren: () => import('./master-produk.module').then(m => m.ProdukModule) },
    { path: 'komponen-thr', loadChildren: () => import('./master-informasi.module').then(m => m.KomponenModule) },
    { path: 'profile-struktur-gaji-by-mkgp-thr', loadChildren: () => import('./master-profile.module').then(m => m.ProfileStrukturGajiByMkgpModule) },

    { path: 'range-masakerja-thr', loadChildren: () => import('./master-khusus.module').then(m => m.RangeMasaKerjaThrModule) },

    //Menu Bonus
    { path: 'periode-bonus', loadChildren: () => import('./master-kalender.module').then(m => m.PeriodeModule) },
    { path: 'range-bonus', loadChildren: () => import('./master-khusus.module').then(m => m.RangeModule) },
    { path: 'produk-bonus', loadChildren: () => import('./master-produk.module').then(m => m.ProdukModule) },
    { path: 'komponen-bonus', loadChildren: () => import('./master-informasi.module').then(m => m.KomponenModule) },
    { path: 'profile-struktur-gaji-by-mkgp-bonus', loadChildren: () => import('./master-profile.module').then(m => m.ProfileStrukturGajiByMkgpModule) },

    //Menu Pensiun
    { path: 'keterangan-alasan-pensiun', loadChildren: () => import('./master-informasi.module').then(m => m.KeteranganAlasanModule) },
    { path: 'kelompok-transaksi-detail-pensiun', loadChildren: () => import('./master-kelompok-transaksi.module').then(m => m.KelompokTransaksiDetailModule) },
    { path: 'master-jenis-dokumen-pensiun', loadChildren: () => import('./master-dokumen.module').then(m => m.JenisDokumenModule) },


    { path: 'range-masakerja-pensiun', loadChildren: () => import('./master-khusus.module').then(m => m.RangeMasaKerjaPensiunModule) },
    { path: 'range-masakerja-kategori', loadChildren: () => import('./master-khusus.module').then(m => m.RangeMasaKerjaKategoriModule) },


    { path: 'cek-pinjaman-pegawai-berhenti', loadChildren: () => import('./master-pegawai-sk.module').then(m => m.CekPinjamanPegawaiBerhentiModule) },

    //Menu Pesangon
    { path: 'periode-pesangon', loadChildren: () => import('./master-kalender.module').then(m => m.PeriodeModule) },
    { path: 'range-pesangon', loadChildren: () => import('./master-khusus.module').then(m => m.RangeModule) },
    { path: 'produk-pesangon', loadChildren: () => import('./master-produk.module').then(m => m.ProdukModule) },
    { path: 'komponen-pesangon', loadChildren: () => import('./master-informasi.module').then(m => m.KomponenModule) },
    { path: 'profile-struktur-gaji-by-mkgp-pesangon', loadChildren: () => import('./master-profile.module').then(m => m.ProfileStrukturGajiByMkgpModule) },

    //Menu Setting Data Fixed
    { path: 'master-settingdatafixed', loadChildren: () => import('./master-aplikasi.module').then(m => m.SettingDataFixedModule) },

    //Menu Sistem Administrator
    { path: 'master-modul-aplikasi-sisadmin', loadChildren: () => import('./master-aplikasi.module').then(m => m.ModulAplikasiModule) },
    { path: 'master-kelompok-transaksi-sisadmin', loadChildren: () => import('./master-kelompok-transaksi.module').then(m => m.KelompokTransaksiModule) },
    { path: 'type-data-object-sisadmin', loadChildren: () => import('./master-aplikasi.module').then(m => m.TypeDataObjectModule) },
    { path: 'master-objek-modul-aplikasi-sisadmin', loadChildren: () => import('./master-aplikasi.module').then(m => m.ObjekModulAplikasiModule) },
    { path: 'master-negara-sisadmin', loadChildren: () => import('./master-wilayah.module').then(m => m.NegaraModule) },
    { path: 'master-matauang-sisadmin', loadChildren: () => import('./master-khusus.module').then(m => m.MatauangModule) },
    { path: 'master-propinsi-sisadmin', loadChildren: () => import('./master-wilayah.module').then(m => m.PropinsiModule) },
    { path: 'master-pulau-sisadmin', loadChildren: () => import('./master-wilayah.module').then(m => m.PulauModule) },
    { path: 'master-kota-kabupaten-sisadmin', loadChildren: () => import('./master-wilayah.module').then(m => m.KotakabupatenModule) },
    { path: 'master-kecamatan-sisadmin', loadChildren: () => import('./master-wilayah.module').then(m => m.KecamatanModule) },
    { path: 'master-desaKelurahan-sisadmin', loadChildren: () => import('./master-wilayah.module').then(m => m.DesaKelurahanModule) },
    { path: 'master-hari-sisadmin', loadChildren: () => import('./master-kalender.module').then(m => m.HariFormModule) },
    { path: 'master-bulan-sisadmin', loadChildren: () => import('./master-kalender.module').then(m => m.BulanModule) },
    { path: 'master-kalender-sisadmin', loadChildren: () => import('./master-kalender.module').then(m => m.KalenderModule) },
    { path: 'master-bahasa-sisadmin', loadChildren: () => import('./master-biodata.module').then(m => m.BahasaModule) },
    { path: 'master-jenis-kelamin-sisadmin', loadChildren: () => import('./master-biodata.module').then(m => m.JenisKelaminModule) },
    { path: 'master-golonganDarah-sisadmin', loadChildren: () => import('./master-biodata.module').then(m => m.GolonganDarahFormModule) },
    { path: 'master-agama-sisadmin', loadChildren: () => import('./master-biodata.module').then(m => m.AgamaModule) },
    { path: 'master-suku-sisadmin', loadChildren: () => import('./master-biodata.module').then(m => m.SukuModule) },
    { path: 'master-produsen-produk-sisadmin', loadChildren: () => import('./master-produk.module').then(m => m.ProdusenProdukModule) },
    { path: 'master-merk-produk-sisadmin', loadChildren: () => import('./master-produk.module').then(m => m.MerkProdukModule) },
    { path: 'master-type-produk-sisadmin', loadChildren: () => import('./master-produk.module').then(m => m.TypeProdukModule) },
    { path: 'master-shio-unsur-sisadmin', loadChildren: () => import('./master-biodata.module').then(m => m.ZodiakUnsurModule) },
    { path: 'master-shio-sisadmin', loadChildren: () => import('./master-biodata.module').then(m => m.ZodiakModule) },
    { path: 'master-shio-unsur-range-sisadmin', loadChildren: () => import('./master-biodata.module').then(m => m.ZodiakUnsurRangeModule) },
    { path: 'master-histori-login-user-sisadmin', loadChildren: () => import('./master-aplikasi.module').then(m => m.HistoriLoginUserModule) },
    { path: 'login-user-sisadmin', loadChildren: () => import('./master-aplikasi.module').then(m => m.LoginUserModule) },
    { path: 'master-messaging-sisadmin', loadChildren: () => import('./master-aplikasi.module').then(m => m.MessagingModule) },
    { path: 'master-kelompok-user', loadChildren: () => import('./master-aplikasi.module').then(m => m.KelompokUserModule) },
    // 

    //master baru
    { path: 'master-penyebab-kematian', loadChildren: () => import('./master-khusus.module').then(m => m.PenyebabKematianModule) },
    { path: 'master-report-jumlah-keterlambatan', loadChildren: () => import('./master-khusus.module').then(m => m.ReportJumlahKeterlambatanKaryawanModule) },
    { path: 'master-kelompok-status', loadChildren: () => import('./master-informasi.module').then(m => m.KelompokStatusModule) },
    { path: 'karir-path', loadChildren: () => import('./master-khusus.module').then(m => m.KarirPathModule) },

    //Menu KPI
    { path: 'master-visi-kpi', loadChildren: () => import('./master-informasi.module').then(m => m.VisiModule) },
    { path: 'master-misi-kpi', loadChildren: () => import('./master-informasi.module').then(m => m.MisiModule) },
    { path: 'master-jenis-dokumen-kpi', loadChildren: () => import('./master-dokumen.module').then(m => m.JenisDokumenModule) },
    { path: 'jenis-deskripsi-kpi', loadChildren: () => import('./master-informasi.module').then(m => m.JenisDeskripsiModule) },
    { path: 'deskripsi-kpi', loadChildren: () => import('./master-informasi.module').then(m => m.DeskripsiModule) },
    { path: 'master-kategori-deskripsi-kpi', loadChildren: () => import('./master-informasi.module').then(m => m.KategoriDeskripsiModule) },
    { path: 'master-spesifikasi-kpi', loadChildren: () => import('./master-kpi.module').then(m => m.SpesifikasiModule) },
    { path: 'master-perspective-kpi', loadChildren: () => import('./master-kpi.module').then(m => m.PerspectiveModule) },
    { path: 'master-kpi-kpi', loadChildren: () => import('./master-kpi.module').then(m => m.KpiModule) },
    { path: 'master-dashboard-monitoring-bsc', loadChildren: () => import('./master-kpi.module').then(m => m.DashboardMonitoringBscModule) },
    { path: 'master-kpi-departemen', loadChildren: () => import('./master-kpi.module').then(m => m.KpiDepartemenModule) },
    { path: 'bsc', loadChildren: () => import('./master-kpi.module').then(m => m.BscModule) },
    { path: 'monitoring-kpi', loadChildren: () => import('./master-kpi.module').then(m => m.MonitoringKpiModule) },
    { path: 'balance-scorecard', loadChildren: () => import('./master-kpi.module').then(m => m.BscModule) },
    { path: 'master-strategy', loadChildren: () => import('./master-kpi.module').then(m => m.StrategyModule) },
    { path: 'master-profile-histori-visi-misi', loadChildren: () => import('./master-kpi.module').then(m => m.ProfileHistoriVisiMisiModule) },

    //master succession planning
    { path: 'master-succession-planning', loadChildren: () => import('./master-succession-planning.module').then(m => m.MasterSuccessionPlanningModule) },
    { path: 'master-profile-histori-job-deskripsi', loadChildren: () => import('./master-succession-planning.module').then(m => m.MasterProfileHistoriJobDeskripsiModule) },

    //master alamat perjalanan dinas tujuan
    { path: 'alamat-perjalanan-dinas-map', loadChildren: () => import('./master-khusus.module').then(m => m.AlamatPerjalananDinasMapModule) },

    //master baru
    { path: 'master-profile-histori-stms', loadChildren: () => import('./master-aplikasi.module').then(m => m.ProfileHistoriStmsModule) },
    { path: 'laporan-detail-ketidakhadiran-karyawan', loadChildren: () => import('./master-khusus.module').then(m => m.LaporanDetailKetidakhadiranKaryawanModule) },

    { path: 'master-range-iki', loadChildren: () => import('./master-range-iki.module').then(m => m.MasterRangeIkiModule) },
    { path: 'master-bank', loadChildren: () => import('./master-pegawai.module').then(m => m.BankModule) },
    { path: 'master-jenis-pengajuan-kredensial', loadChildren: () => import('./master-informasi.module').then(m => m.JenisPengajuanKredensialModule) },
    { path: 'master-jenis-pengajuan-sip', loadChildren: () => import('./master-informasi.module').then(m => m.JenisPengajuanSipModule) },
    { path: 'monitoring-sk-kewenangan-klinis', loadChildren: () => import('./master-pegawai.module').then(m => m.MonitoringSkKewenanganKlinisModule) },
    { path: 'monitoring-str-sip-pegawai', loadChildren: () => import('./master-pegawai.module').then(m => m.MonitoringStrSipPegawaiModule) },
];
