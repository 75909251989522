import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService, InfoService } from '../../global';

@Component({
  selector: 'app-redirect-menu-perubahan-pinjaman',
  templateUrl: './redirect-menu-perubahan-pinjaman.component.html',                                                                                                                     
  styleUrls: ['./redirect-menu-perubahan-pinjaman.component.css']
})
export class RedirectMenuPerubahanPinjamanComponent implements OnInit {

  link  = [
    'daftar-kirim-pengajuan-hold-pinjaman-v2',
    'daftar-pengajuan-hold-pinjaman-v2',
    'daftar-terima-pengajuan-hold-pinjaman-v2'
  ];

  constructor(
    private route: Router,
    private info: InfoService,
    private settingsService: SettingsService
  ) { }

  ngOnInit() {
    let urlList = this.settingsService.getCurrentURL();
        for (let i=0; i<this.link.length; i++){
            if (!(urlList['/' + this.link[i]] == undefined || urlList['/' + this.link[i]] == null || urlList['/' + this.link[i]] != 1)){
                localStorage.setItem('perubahan-pinjaman-menu', String(i));
                this.route.navigate([this.link[i]]);
                break;
            }
        }
  }

}
