import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MenuItem } from 'primeng/primeng';
import { Router } from '@angular/router';

@Component({
   selector: 'app-menu-penilaian-kerja',
   templateUrl: './menu-penilaian-kerja.component.html',
   styleUrls: ['./menu-penilaian-kerja.component.css'],
   encapsulation: ViewEncapsulation.None
})
export class MenuPenilaianKerjaComponent implements OnInit {
   items: MenuItem[]
   activeIndex: number = 0
   link = [
      'pengajuan-penilaian-kerja',
      'daftar-pengajuan-penilaian-kerja',
      'daftar-terima-pengajuan-penilaian-kerja'
   ]

   constructor(
      private route: Router
   ) { }

   ngOnInit() {
      let urlCurrent = this.route.url
      for (let i = 0; i < this.link.length; i++) {
         if (urlCurrent == '/' + this.link[i]) {
            this.activeIndex = i
            break
         }
      }

      this.items = [
         {
            label: 'Pengajuan Penilaian Kerja',
            command: (e: any) => {
               this.activeIndex = 0
               this.route.navigate(['pengajuan-penilaian-kerja'])
               localStorage.setItem('penilaian-kerja-menu', String(this.activeIndex))
            }
         },
         {
            label: 'Daftar Pengajuan Penilaian Kerja',
            command: (e: any) => {
               this.activeIndex = 1
               this.route.navigate(['daftar-pengajuan-penilaian-kerja'])
               localStorage.setItem('penilaian-kerja-menu', String(this.activeIndex))
            }
         },
         {
            label: 'Daftar Terima Pengajuan Penilaian Kerja',
            command: (e: any) => {
               this.activeIndex = 2
               this.route.navigate(['daftar-terima-pengajuan-penilaian-kerja'])
               localStorage.setItem('penilaian-kerja-menu', String(this.activeIndex))
            }
         },
      ]
   }
}