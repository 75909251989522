export const routeOrientasi = [
    { path: 'pengajuan-orientasi-pegawai', loadChildren : () => import('./page-orientasi.module').then(m => m.PengajuanOrientasiPegawaiModule) },
    { path: 'daftar-pengajuan-orientasi-pegawai', loadChildren : () => import('./page-orientasi.module').then(m => m.DaftarPengajuanOrientasiPegawaiModule) },
    { path: 'daftar-pengajuan-orientasi-pegawai-v2', loadChildren : () => import('./page-orientasi.module').then(m => m.DaftarPengajuanOrientasiPegawaiV2Module) },
    { path: 'pelaksanaan-orientasi-pegawai', loadChildren : () => import('./page-orientasi.module').then(m => m.PelaksanaanOrientasiPegawaiModule) },
    { path: 'penilaian-evaluasi-hasil-orientasi', loadChildren : () => import('./page-orientasi.module').then(m => m.PenilaianEvaluasiHasilOrientasiModule) },
    { path: 'history-penilaian-evaluasi', loadChildren : () => import('./page-orientasi.module').then(m => m.HistoryPenilaianEvaluasiModule) },
    { path: 'daftar-kirim-pengajuan-orientasi-pegawai', loadChildren : () => import('./page-orientasi.module').then(m => m.DaftarKirimPengajuanOrientasiPegawaiModule) },
    { path: 'daftar-terima-pengajuan-orientasi-pegawai', loadChildren : () => import('./page-orientasi.module').then(m => m.DaftarTerimaPengajuanOrientasiPegawaiModule) },
    { path: 'daftar-list-orientasi-pegawai', loadChildren : () => import('./page-orientasi.module').then(m => m.DaftarListPegawaiModule) },
    { path: 'history-penilaian-evaluasi-v2', loadChildren : () => import('./page-orientasi.module').then(m => m.HistoryPenilaianEvaluasiV2Module) },
    { path: 'daftar-kirim-pengajuan-orientasi-pegawai-v2', loadChildren : () => import('./page-orientasi.module').then(m => m.DaftarKirimPengajuanOrientasiPegawaiV2Module) },
    { path: 'daftar-terima-pengajuan-orientasi-pegawai-v2', loadChildren : () => import('./page-orientasi.module').then(m => m.DaftarTerimaPengajuanOrientasiPegawaiV2Module) },
    { path: 'daftar-list-orientasi-pegawai-v2', loadChildren : () => import('./page-orientasi.module').then(m => m.DaftarListPegawaiV2Module) },
];
