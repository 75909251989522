import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MenuItem } from 'primeng/primeng';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-validasi-sip-str',
  templateUrl: './menu-validasi-sip-str.component.html',
  styleUrls: ['./menu-validasi-sip-str.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MenuValidasiSipStrComponent implements OnInit {
  items: any[];
  activeIndex: number = 0;
  link  = [
    'pengajuan-validasi-sip-str',
    'daftar-terima-pengajuan-validasi-sip-str'
  ];
  constructor(
    private route: Router
  ) { }

  ngOnInit() {
    let urlCurrent = this.route.url;
    for (let i = 0; i < this.link.length; i++) {
        if ( urlCurrent == '/' + this.link[i] ) {
            this.activeIndex = i;
            break;
        }
    }

    this.items = [
      {
          label: 'Pengajuan Validasi SIP dan STR',
          command: (event: any) => {
              this.activeIndex = 0;
              this.route.navigate(['pengajuan-validasi-sip-str']);
              localStorage.setItem('validasi-sip-str-menu', String(this.activeIndex));
          }
      },
      {
        label: 'Daftar Terima Pengajuan Validasi SIP dan STR',
        command: (event: any) => {
            this.activeIndex = 2;
            this.route.navigate(['daftar-terima-pengajuan-validasi-sip-str']);
            localStorage.setItem('validasi-sip-str-menu', String(this.activeIndex));
        }
    }

      ];
  }

}
