export const routeKarir = [
    { path: 'pengajuan-reward-pegawai', loadChildren: () => import('./page-karir.module').then(m => m.PengajuanRewardPegawaiModule) },
    { path: 'tambah-pengajuan-validasi-sip-str', loadChildren: () => import('./page-karir.module').then(m => m.TambahPengajuanValidasiSipStrModule) }, 
    { path: 'pengajuan-sanksi-pegawai', loadChildren: () => import('./page-karir.module').then(m => m.PengajuanSanksiPegawaiModule) },
    { path: 'daftar-pengajuan-reward', loadChildren: () => import('./page-karir.module').then(m => m.DaftarPengajuanRewardModule) },
    { path: 'daftar-pengajuan-reward-v2', loadChildren: () => import('./page-karir.module').then(m => m.DaftarPengajuanRewardV2Module) },
    { path: 'daftar-pengajuan-sanksi', loadChildren: () => import('./page-karir.module').then(m => m.DaftarPengajuanSanksiModule) },
    { path: 'daftar-pengajuan-sanksi-v2', loadChildren: () => import('./page-karir.module').then(m => m.DaftarPengajuanSanksiV2Module) },
    { path: 'evaluasi-jabatan-pegawai-v2', loadChildren: () => import('./page-karir.module').then(m => m.EvaluasiJabatanPegawaiModule) },
    { path: 'evaluasi-jabatan-pegawai', loadChildren: () => import('./page-karir.module').then(m => m.EvaluasiJabatanPegawaiSingleModule) },
    { path: 'daftar-evaluasi-jabatan-pegawai-v2', loadChildren: () => import('./page-karir.module').then(m => m.DaftarEvaluasiJabatanPegawaiModule) },
    { path: 'daftar-evaluasi-jabatan-pegawai', loadChildren: () => import('./page-karir.module').then(m => m.DaftarEvaluasiJabatanPegawaiSingleModule) },
    { path: 'pengajuan-transisi-karir', loadChildren: () => import('./page-karir.module').then(m => m.PengajuanTransisiKarirModule) },
    { path: 'daftar-list-pegawai-evaluasi-v2', loadChildren: () => import('./page-karir.module').then(m => m.DaftarListPegawaiEvaluasiModule) },
    { path: 'daftar-list-pegawai-evaluasi', loadChildren: () => import('./page-karir.module').then(m => m.DaftarListPegawaiEvaluasiSingleModule) },
    { path: 'daftar-pengajuan-transisi-karir', loadChildren: () => import('./page-karir.module').then(m => m.DaftarPengajuanTransisiKarirModule) },
    { path: 'daftar-pengajuan-transisi-karir-v2', loadChildren: () => import('./page-karir.module').then(m => m.DaftarPengajuanTransisiKarirV2Module) },
    { path: 'restrukturisasi-komponen-gaji-v2', loadChildren: () => import('./page-karir.module').then(m => m.RestrukturisasiKomponenGajiModule) },
    { path: 'restrukturisasi-komponen-gaji', loadChildren: () => import('./page-karir.module').then(m => m.RestrukturisasiKomponenGajiSingleModule) },
    { path: 'daftar-terima-pengajuan-transisi-karir', loadChildren: () => import('./page-karir.module').then(m => m.DaftarTerimaPengajuanTransisiKarirModule) },
    { path: 'daftar-kirim-pengajuan-transisi-karir', loadChildren: () => import('./page-karir.module').then(m => m.DaftarKirimPengajuanTransisiKarirModule) },
    { path: 'daftar-terima-pengajuan-transisi-karir-v2', loadChildren: () => import('./page-karir.module').then(m => m.DaftarTerimaPengajuanTransisiKarirV2Module) },
    { path: 'daftar-kirim-pengajuan-transisi-karir-v2', loadChildren: () => import('./page-karir.module').then(m => m.DaftarKirimPengajuanTransisiKarirV2Module) },
    { path: 'daftar-kirim-pengajuan-reward', loadChildren: () => import('./page-karir.module').then(m => m.DaftarKirimPengajuanRewardModule) },
    { path: 'pengajuan-validasi-sip-str', loadChildren: () => import('./page-karir.module').then(m => m.PengajuanValidasiSipStrModule) },
    { path: 'daftar-kirim-pengajuan-reward-v2', loadChildren: () => import('./page-karir.module').then(m => m.DaftarKirimPengajuanRewardV2Module) },
    { path: 'daftar-terima-pengajuan-reward', loadChildren: () => import('./page-karir.module').then(m => m.DaftarTerimaPengajuanRewardModule) },
    { path: 'daftar-terima-pengajuan-reward-v2', loadChildren: () => import('./page-karir.module').then(m => m.DaftarTerimaPengajuanRewardV2Module) },
    { path: 'daftar-terima-pengajuan-validasi-sip-str', loadChildren: () => import('./page-karir.module').then(m => m.DaftarTerimaPengajuanValidasiSipStrModule)},
    { path: 'daftar-kirim-pengajuan-sanksi', loadChildren: () => import('./page-karir.module').then(m => m.DaftarKirimPengajuanSanksiModule) },
    { path: 'daftar-terima-pengajuan-sanksi', loadChildren: () => import('./page-karir.module').then(m => m.DaftarTerimaPengajuanSanksiModule) },
    { path: 'daftar-kirim-pengajuan-sanksi-v2', loadChildren: () => import('./page-karir.module').then(m => m.DaftarKirimPengajuanSanksiV2Module) },
    { path: 'daftar-terima-pengajuan-sanksi-v2', loadChildren: () => import('./page-karir.module').then(m => m.DaftarTerimaPengajuanSanksiV2Module) },
    { path: 'histori-karir-awal', loadChildren: () => import('./page-karir.module').then(m => m.HistoriKarirAwalModule) },
    { path: 'histori-karir', loadChildren: () => import('./page-karir.module').then(m => m.HistoriKarirModule) },
    { path: 'daftar-monitoring-transisi-karir-pegawai', loadChildren: () => import('./page-karir.module').then(m => m.DaftarMonitoringTransisiKarirPegawaiModule) },
    { path: 'restrukturisasi-gaji', loadChildren: () => import('./page-karir.module').then(m => m.RestrukturisasiGajiModule) },
    { path: 'riwayat-karir-pegawai', loadChildren: () => import('./page-karir.module').then(m => m.RiwayatKarirPegawaiModule) },
    { path: 'restrukturisasi-gaji-v2', loadChildren: () => import('./page-karir.module').then(m => m.RestrukturisasiGajiV2Module) },
    { path: 'pengajuan-transisi', loadChildren: () => import('./page-karir.module').then(m => m.PengajuanTransisiModule) },
    { path: 'daftar-pembatalan-transisi-karir', loadChildren: () => import('./page-karir.module').then(m => m.DaftarPembatalanTransisiKarirModule) },
    { path: 'daftar-terima-pembatalan-transisi-karir', loadChildren: () => import('./page-karir.module').then(m => m.DaftarTerimaPembatalanTransisiKarirModule) },
    { path: 'pengajuan-penilaian-kerja', loadChildren: () => import('./page-karir.module').then(m => m.PengajuanPenilaianKerjaModule) },
    { path: 'daftar-pengajuan-penilaian-kerja', loadChildren: () => import('./page-karir.module').then(m => m.DaftarPengajuanPenilaianKerjaModule) },
    { path: 'daftar-terima-pengajuan-penilaian-kerja', loadChildren: () => import('./page-karir.module').then(m => m.DaftarTerimaPengajuanPenilaianKerjaModule) },
    { path: 'penilaian-kerja', loadChildren: () => import('./page-karir.module').then(m => m.PenilaianKerjaModule) },
    { path: 'pengajuan-pelatihan-pkb-sponsorship', loadChildren: () => import('./page-karir.module').then(m => m.PengajuanPelatihanPkbSponsorshipModule) },
    { path: 'tambah-pengajuan-pelatihan-pkb-sponsorship', loadChildren: () => import('./page-karir.module').then(m => m.TambahPengajuanPelatihanPkbSponsorshipModule) },
    { path: 'daftar-peserta-pelatihan-pkb-sponsorship', loadChildren: () => import('./page-karir.module').then(m => m.DaftarPesertaPelatihanPkbSponsorshipModule) },
    { path: 'tambah-peserta-pelatihan-pkb-sponsorship', loadChildren: () => import('./page-karir.module').then(m => m.TambahPesertaPelatihanPkbSponsorshipModule) },
    { path: 'daftar-pengajuan-pelatihan-pkb-sponsorship', loadChildren: () => import('./page-karir.module').then(m => m.DaftarPengajuanPelatihanPkbSponsorshipModule) },
    { path: 'daftar-terima-pengajuan-pelatihan-pkb-sponsorship', loadChildren: () => import('./page-karir.module').then(m => m.DaftarTerimaPengajuanPelatihanPkbSponsorshipModule) },
];
