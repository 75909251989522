export { AppComponent } from './app.component';
export { AppMenuComponent, AppSubMenu } from './app.menu.component';
export { AppSideBarComponent } from './app.sidebar.component';
export { AppSidebarTabContent } from './app.sidebartabcontent.component';
export { AppTopBar } from './app.topbar.component';
export { AppFooter } from './app.footer.component';

import * as app from './';

export const apps = [
    app.AppComponent,   
    app.AppMenuComponent,   
    app.AppSubMenu,   
    app.AppSideBarComponent,   
    app.AppSidebarTabContent,   
    app.AppTopBar,   
    app.AppFooter   
];


import * as ng from 'primeng/primeng';
import * as tbl from 'primeng/table';

////////////////////////////////////
/// Dipakai di Global Component ////
////////////////////////////////////

export const primeAppModule = [
    ng.ButtonModule,
    ng.ConfirmDialogModule,
    ng.DialogModule,
    ng.DragDropModule,
    ng.DropdownModule,
    ng.FileUploadModule,
    ng.InputTextModule,
    ng.ProgressBarModule,
    ng.StepsModule,
    ng.ToolbarModule,
    ng.BreadcrumbModule,
    ng.TooltipModule,
    ng.SplitButtonModule
];


///////////////////////////////
/// PrimeNg Module         ///
/// Berurutan sesuai Abjad ///
//////////////////////////////

export const primeNgModule = [
    // ng.AccordionModule,
    ng.AutoCompleteModule,
    ng.BlockUIModule,
    ng.BreadcrumbModule,
    ng.ButtonModule,
    //ng.CalendarModule,
    // ng.CarouselModule,
    ng.ChartModule,
    ng.CheckboxModule,
    // ng.ChipsModule,
    // ng.CodeHighlighterModule,
    // ng.ColorPickerModule,
    ng.ConfirmDialogModule,
    ng.ContextMenuModule,
    // ng.DataGridModule,
    // ng.DataListModule,
    // ng.DataScrollerModule,
//    ng.DataTableModule,
    tbl.TableModule,
    ng.DialogModule,
    // ng.DragDropModule,
    ng.DropdownModule,
    // ng.EditorModule,
    ng.FieldsetModule,
    ng.FileUploadModule,
    // ng.GalleriaModule,
    // ng.GMapModule,
    // ng.GrowlModule,
    // ng.LightboxModule,
    // ng.ListboxModule,
    // ng.InplaceModule,
    ng.InputMaskModule,
    // ng.InputSwitchModule,
    ng.InputTextareaModule,
    ng.InputTextModule,
    // ng.MegaMenuModule,
    // ng.MessagesModule,
    // ng.MenubarModule,
    // ng.MenuModule,
    ng.MultiSelectModule,
    // ng.OrderListModule,
    ng.OrganizationChartModule,
    ng.OverlayPanelModule,
    ng.PaginatorModule,
    // ng.PanelMenuModule,
    ng.PanelModule,
    // ng.PasswordModule,
    // ng.PickListModule,
    // ng.ProgressBarModule,
    ng.ProgressSpinnerModule,
    ng.RadioButtonModule,
    // ng.RatingModule,
    ng.ScheduleModule,
    ng.ScrollPanelModule,
    // ng.SelectButtonModule,
    ng.SharedModule,
    ng.SidebarModule,    
    // ng.SlideMenuModule,
    // ng.SliderModule,
    ng.SpinnerModule,
    ng.SplitButtonModule,
    // ng.TabMenuModule,
    ng.TabViewModule,
    // ng.TerminalModule,
    // ng.TieredMenuModule,
    // ng.ToggleButtonModule,
    ng.ToolbarModule,
    ng.TooltipModule,
    ng.TreeModule,
    // ng.TreeTableModule,
    // ng.TriStateCheckboxModule,
];

