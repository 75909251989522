import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/primeng';
import { AuthGuard, PreviousRouteDetector } from '../../../global'; 
import { Router } from '@angular/router';

@Component({
  selector: 'app-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss']
})
export class SessionTimeoutComponent implements OnInit {

    bahasa: SelectItem[];
    bahasaTerpilih: string = 'id';
    returnUrl: string;
    sudahLogin = false

    constructor(private route: PreviousRouteDetector,
        private authGuard: AuthGuard,
        private router: Router){
        this.bahasa = [];
        this.bahasa.push({label : 'Bahasa Indonesia', value: 'id'});
        this.bahasa.push({label : 'English', value: 'en'});
    }

    ngOnInit() {     
        this.authGuard.logout();
        this.returnUrl = this.route.getPreviousUrl();
        if (this.returnUrl == '/viewer-absensi' || this.returnUrl == '/login'){
            this.returnUrl = '/'
        }
        localStorage.setItem('session.url',this.returnUrl)
    }   
    
    login(){
        this.router.navigate(['login'])
    }

}