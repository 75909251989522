import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { AuthGuard } from './global';
import { Subscription } from 'rxjs/Subscription';
import * as moment from 'moment';

@Component({
    selector: 'app-footer',
    template: `
    <!-- <div class="footer clearfix">
    </div> -->
    <!-- start -->
    <!-- edit by uiux 110917 -->
    <!-- fungsi menampilkan help & support bar -->
    <!-- <div class="footer clearfix">
        <img src="assets/layout/images/logo-bottis-hris.png" />
        <div class="footer-links">
            <span>{{ namaLogin }}</span>
            <span class="link-divider">|</span>
            <span>{{ namaPerusahaan }}</span>
            <span class="link-divider">|</span>
            <span>Copyright &copy;2018 Bottis HRIS by - <strong>PT. Jasamedika Saranatama</strong></span>
        </div>
    </div> -->
    <div class="bottom-bar ui-g-12">
        <div class="ui-g-8">
           <div class="footer-links" style="color: #215E43;"> 
               <span>{{'Pengguna: ' | translate}} </span>
               <span><strong>{{ namaLogin }}</strong></span>
               <span>&nbsp;&nbsp;</span>
               <!--&#8594; <span class="link-divider">{{'dari' | translate}}</span> -->
               <span> | {{'profil: ' | translate | titlecase}} </span>
               <span><strong>{{ namaPerusahaan }} </strong></span>                
               <span> - </span>
               <span><strong>{{ namaLokasi }}</strong></span> 
               <span> - </span>
               <span><strong>{{ namaDepartemen }}</strong></span>
               <span>&nbsp;&nbsp;</span>
               <span> | {{'Masuk: ' | translate}} </span>
               <span><strong>{{ waktuLogin }}</strong></span>
           </div> 
        </div>
        <div class="ui-g-4">
            <div class="footer-links" style="color: #444444; text-align:right;">
                <!-- <a href="#" class="first">Terms</a> -->
                <!-- <span class="link-divider">|</span> -->
                <!-- <a href="#">About</a>> -->
                <!-- <span class="link-divider">|</span> -->
                <!-- <a href="#">Privacy</a> -->
                <!-- <span class="link-divider">|</span> -->
                <!-- <a href="#">Contact</a> -->
                <!-- <span class="link-divider">|</span> -->
                <!-- <a href="#">Map</a> -->
                <!-- <span class="link-divider">|</span> -->
                <span>Copyright By <span style='font-family:gadugibold;'>Transmedic</span></span>
            </div>
        </div>
        <!-- <div class="help-support-wrapper ui-g-2">
            <span class="help-support ui-g-11"><i class="fa fa-question-circle-o"> </i> Help & Support</span>
            <span class="help-support ui-g-1" style="text-align: right;"><i class="fa fa-chevron-up"></i></span>
        </div> -->
    </div>
    <!-- fungsi menampilkan help & support bar -->
    <!-- end -->
    `
})
export class AppFooter implements OnInit, OnDestroy {
    constructor(private authGuard : AuthGuard) { }
    
    namaLogin: string = "";
    namaPerusahaan : string = "";
    namaLokasi : string = "";
    namaDepartemen: string = '';
    waktuLogin: any;

    // private nmLS: Subscription;
    // private nmPS: Subscription;

    ngOnDestroy(){
        // debugger;
        // this.nmLS.unsubscribe();
        // this.nmPS.unsubscribe();
    }

    ngOnInit(){
        let loginTime;
        this.namaLogin = this.authGuard.getNamaLogin();
        this.namaLokasi = this.authGuard.getNamaLokasi();
        this.namaPerusahaan = this.authGuard.getNamaPerusahaan();
        this.namaDepartemen = this.authGuard.getNamaDepartemen();
        loginTime = this.authGuard.getWaktuLogin();
        this.waktuLogin = moment(new Date(loginTime)).format('DD-MMM-YYYY HH:mm');
        
        // this.nmLS = this.authGuard.getNamaLogin().subscribe(nama => { this.namaLogin = nama; debugger; });
        // this.nmPS = this.authGuard.getNamaPerusahaan().subscribe(nama => { this.namaPerusahaan = nama;  debugger; });       
    }
}