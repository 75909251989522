import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MenuItem } from 'primeng/primeng';
import { Router } from '@angular/router';
import { SettingsService } from '../../global';

@Component({
  selector: 'app-menu-pinjaman',
  templateUrl: './menu-pinjaman.component.html',
  styleUrls: ['./menu-pinjaman.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MenuPinjamanComponent implements OnInit {
  items: MenuItem[];
  activeIndex: number = 0;
  link  = [
    'daftar-kirim-pengajuan-pinjaman-v2',
    'daftar-pengajuan-pinjaman-v2',
    'daftar-terima-pengajuan-pinjaman-v2'
  ];
  constructor(
    private route: Router,
    private settingsService: SettingsService
  ) { }

  ngOnInit() {
    
    let urlCurrent = this.route.url;
    for (let i = 0; i < this.link.length; i++) {
        if ( urlCurrent == '/' + this.link[i] ) {
            // localStorage.setItem('pinjaman-menu', String(i));
            // this.route.navigate([this.link[i]]);
            this.activeIndex = i;
            break;
        }
    }

    // this.activeIndex = parseInt(localStorage.getItem('pinjaman-menu'));
    this.items = [
      {
          label: 'Pengajuan Pinjaman',
          command: (event: any) => {
              this.activeIndex = 0;
              this.route.navigate(['daftar-kirim-pengajuan-pinjaman-v2']);
              localStorage.setItem('pinjaman-menu', String(this.activeIndex));
          }
      },
      {
          label: 'Daftar Pengajuan Pinjaman',
          command: (event: any) => {
              this.activeIndex = 1;
              this.route.navigate(['daftar-pengajuan-pinjaman-v2']);
              localStorage.setItem('pinjaman-menu', String(this.activeIndex));
          }
      },
      {
        label: 'Daftar Terima Pengajuan Pinjaman',
        command: (event: any) => {
            this.activeIndex = 2;
            this.route.navigate(['daftar-terima-pengajuan-pinjaman-v2']);
            localStorage.setItem('pinjaman-menu', String(this.activeIndex));
        }
    }

      ];
  }

}
