import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { routeAuth } from './page/auth/path';
import { routeRedirect } from './steps/path';
import { routeDashboard } from './page/dashboard/path';
import { routeRegistrasi } from './page/database-histori-pegawai/path';
import { routeOrientasi } from './page/orientasi-pegawai/path';
import { routeKarir } from './page/karir/path';
import { routePhk } from './page/phk/path';
import { routePenggajianPajak } from './page/penggajian-pajak/path';
import { routeKehadiran } from './page/kehadiran/path';
import { routePersonalAffairs } from './page/personal-affairs/path';
import { routeBsc } from './page/bsc/path';

import { routeMaster } from './page/master/path';
import { routeMasterSK } from './page/master/path-sk';
import { routeMasterMap } from './page/master/path-map';
import { routeMasterData } from './page/master/path-master';
import { routePesan } from './page/pesan/path';

export const routes: Routes = [
	...routeAuth,
	...routeRedirect,
	...routeDashboard,
	...routeRegistrasi,
	...routeOrientasi,
	...routeKarir,
	...routePhk,
	...routePenggajianPajak,	
 	...routeKehadiran,
	...routePersonalAffairs,
	...routeMaster,
	...routePesan,
	 {
         path: 'master-sk',
         children:  [...routeMasterSK]
     },
	 {
          path: 'master-map',
          children:  [...routeMasterMap]
      },
	  {
          path: 'master-data',
          children:  [...routeMasterData]
      },
	...routeBsc
];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, { useHash: true});
