import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MenuItem } from 'primeng/primeng';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-evaluasi-jabatan',
  templateUrl: './menu-evaluasi-jabatan.component.html',
  styleUrls: ['./menu-evaluasi-jabatan.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MenuEvaluasiJabatanComponent implements OnInit {
  items: MenuItem[];
  activeIndex: number = 0;
  disStep2: boolean;
  link  = [
    'daftar-list-pegawai-evaluasi-v2',
    'evaluasi-jabatan-pegawai-v2',
    'daftar-evaluasi-jabatan-pegawai-v2',
    'daftar-kirim-pengajuan-transisi-karir-v2',
    // 'daftar-pengajuan-transisi-karir-v2',
    'daftar-terima-pengajuan-transisi-karir-v2',
    // 'restrukturisasi-komponen-gaji-v2'
    'restrukturisasi-gaji-v2'
  ];

  constructor(
    private route: Router
  ) { }

  ngOnInit() {
    this.disStep2 = true;

    let urlCurrent = this.route.url;
    for (let i = 0; i < this.link.length; i++) {
        if ( urlCurrent == '/' + this.link[i] ) {
            this.activeIndex = i;
            break;
        }
    }

    // this.activeIndex = parseInt(localStorage.getItem('evaluasi-jabatan-menu'));
    if (this.activeIndex === 1) {
      this.disStep2 = false;
    }
    this.items = [
      {
          label: 'Daftar List Pegawai',
          command: (event: any) => {
              this.activeIndex = 0;
              this.route.navigate(['daftar-list-pegawai-evaluasi-v2']);
              localStorage.setItem('evaluasi-jabatan-menu', String(this.activeIndex));
          }
      },
      {
          label: 'Penilaian Evaluasi Jabatan Pegawai',
          command: (event: any) => {
              this.activeIndex = 1;
              this.route.navigate(['evaluasi-jabatan-pegawai-v2']);
              localStorage.setItem('evaluasi-jabatan-menu', String(this.activeIndex));
          },
          disabled: this.disStep2
      },
      {
        label: 'Daftar Evaluasi Jabatan Pegawai',
        command: (event: any) => {
            this.activeIndex = 2;
            this.route.navigate(['daftar-evaluasi-jabatan-pegawai-v2']);
            localStorage.setItem('evaluasi-jabatan-menu', String(this.activeIndex));
        }
      }
      ,
      {
        label: 'Pengajuan Transisi Karir',
        command: (event: any) => {
            this.activeIndex = 3;
            this.route.navigate(['daftar-kirim-pengajuan-transisi-karir-v2']);
            localStorage.setItem('evaluasi-jabatan-menu', String(this.activeIndex));
        }
      }
      // ,
      // {
      //   label: 'Daftar Pengajuan Transisi Karir',
      //   command: (event: any) => {
      //       this.activeIndex = 4;
      //       this.route.navigate(['daftar-pengajuan-transisi-karir-v2']);
      //       localStorage.setItem('evaluasi-jabatan-menu', String(this.activeIndex));
      //   }
      // }
      ,
      {
        label: 'Daftar Terima Pengajuan Transisi Karir',
        command: (event: any) => {
            this.activeIndex = 4;
            this.route.navigate(['daftar-terima-pengajuan-transisi-karir-v2']);
            localStorage.setItem('evaluasi-jabatan-menu', String(this.activeIndex));
        }
      }
      ,
      {
        label: 'Restrukturisasi Komponen Gaji',
        command: (event: any) => {
            this.activeIndex = 5;
            // this.route.navigate(['restrukturisasi-komponen-gaji-v2']);
            this.route.navigate(['restrukturisasi-gaji-v2']);
            localStorage.setItem('evaluasi-jabatan-menu', String(this.activeIndex));
        }
      }

      ];
  }

}
